


import { Accordion, Text, Badge, Group, Box, Grid } from '@mantine/core';
import { IconDashboard, IconDatabase, IconFileText, IconChevronDown, IconChevronUp, IconLayoutGrid } from '@tabler/icons';

// Import your data structure
import data from '../dummy-data/test-data.json'; // Path to your JSON file

const DataExplorerPage = () => {
  // Assuming you have your structured data imported or passed as props
  return (
    <Box style={{ margin: '1rem' }}> {/* Use `style` instead of `sx` */}    
      <Grid align="center" justify="space-between">
        <Grid.Col span={4}> {/* Adjust the span as necessary based on content and preference */}
          <Text fw={500} size="lg">{data.name}</Text>
        </Grid.Col>
        <Grid.Col span={4} style={{ textAlign: 'center' }}> {/* Centered; adjust for preference */}
          <Text>Size: {data.fileSize}</Text>
        </Grid.Col>
        <Grid.Col span={4} style={{ textAlign: 'right' }}> {/* Right-aligned; adjust for preference */}
          <Text>Date Processed: {data.dateProcessed}</Text>
        </Grid.Col>
      </Grid>
      <Accordion defaultValue={[]} multiple chevronPosition="right" chevron={<IconChevronDown size={16} />} mx="auto" >
        <Accordion.Item value="dashboards">
          <Accordion.Control icon={<IconDashboard size={20} />}>Dashboards</Accordion.Control>
          <Accordion.Panel>
            {data.contents.dashboards.map((dashboard) => (
              <Text key={dashboard} style={{ paddingLeft: '2rem' }}>
                <IconLayoutGrid size={16} /> {dashboard}
              </Text>
            ))}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="datasources">
          <Accordion.Control icon={<IconDatabase size={20} />}>Datasources</Accordion.Control>
          <Accordion.Panel>
            {data.contents.datasources.map((datasource) => (
              <Text key={datasource} style={{ paddingLeft: '2rem' }}>
                <IconFileText size={16} /> {datasource}
              </Text>
            ))}
          </Accordion.Panel>
        </Accordion.Item>

        {/* Works similarly for worksheets with additional nesting for data sources */}
        <Accordion.Item value="worksheets">
          <Accordion.Control icon={<IconFileText size={20} />}>Worksheets</Accordion.Control>
          <Accordion.Panel>
            {data.contents.worksheets.map((worksheet) => (
              <Accordion key={worksheet.name} defaultValue={[]} multiple>
                <Accordion.Item value={worksheet.name}>
                  <Accordion.Control icon={<IconFileText size={16} />}>{worksheet.name}</Accordion.Control>
                  <Accordion.Panel>
                    {worksheet.datasources.map((datasource) => (
                      <Group key={datasource.name} style={{ paddingLeft: '2rem' }}>
                        <Text fw={500}>Datasource: {datasource.name}</Text>
                        {datasource.fields.map((field) => (
                          <Badge key={field.name} color="blue" variant="light">
                            {field.name} ({field.type})
                          </Badge>
                        ))}
                      </Group>
                    ))}
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

export default DataExplorerPage;


