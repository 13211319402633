import React, { useState } from "react";
import { Combobox, InputBase, ScrollArea, useCombobox } from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Category } from "../../types/interfaces";
import { addNewCategory } from "../../util/http";
import { useNotificationStore } from "../../store/useNotificationStore";
import { useLoginStore } from "../../store/useLoginStore";

/**
 * This is copied directly from Mantine v7.0.3
 * https://mantine.dev/combobox/?e=SelectCreatable
 * It appears version 7's Select doesn't have the same
 * properties as previous versions, and the following
 * complains on the "creatable" property and "getCreateLabel"
 *
 *  <Select
 *        label="Category"
 *        placeholder="Pick one"
 *        searchable
 *        value={selectCategory}
 *        onChange={setSelectCategory}
 *        data={categories}
 *        maxDropdownHeight={250}
 *        creatable
 *        getCreateLabel={(query) => `+ Create ${query}`}
 *         onCreate={(query) => {
 *           setSelectCategory(query);
 *           createCategoryMutation.mutate({
 *            name: query,
 *           });
 *          return query;
 *         }}
 *       />
 *
 * So in the spirit of learning, I'll see what Mantine's example code is all
 * about. It might have nothing in common with what I want to do (a drop
 * in replacement for the original Silkroad code has).
 */

const groceries = ["🤗︎ Hug", "🐣 Chick"];

// Datatypes for the two passed in props.
// Using this as my guide: https://github.com/danvk/prop-drilling/blob/master/src/00a-initial.tsx
// When invoking onOptionSubmitData, I had to cast current, like so:
// onOptionSubmitData((current) => [...(current as string[]), search]);
// adding "as string[]".
// Otherwise, in the vscode editor, it was saying:
// Type 'SetStateAction<string[]>' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
// I found the answer here:
// https://stackoverflow.com/questions/70897454/type-must-have-a-symbol-iterator-method-that-returns-an-iterator

export const SelectCreatable = ({
  listData,
  selectedValue,
  setSelectedValue,
}: {
  listData: string[];
  selectedValue: string | null;
  setSelectedValue: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  // This represents the list of categories
  // listData is passed in from the calling function
  const [data, setData] = useState(listData);
  // ADT Default the search to the search to the selected value, since
  // ProducDetailsAdminManagerView also uses this component, and it
  // passes in a selectedValue. In the ComboBox target the default is
  // getting set to "{search}"
  const [search, setSearch] = useState(selectedValue || "");
  const notificationStore = useNotificationStore();

  // This is a singleton, instantiated in App.tsx
  const queryClient = useQueryClient();

  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (requestBody: { name: string }) =>
      addNewCategory({
        requestBody,
        accessToken: token,
      }),

    onSuccess: (newCategory: Category) => {
      // ADT adding next
      notificationStore.successNotification(
        "Category " + newCategory.name + " created successfully"
      );
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
    onError: (data: Error) => {
      notificationStore.errorNotification(
        data.message,
        `Could not create new category`
      );
    },
  });

  const exactOptionMatch = data.some((item) => item === search);
  const filteredOptions = exactOptionMatch
    ? data
    : data.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase().trim())
      );

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === "$create") {
          setData((prevState) => [...prevState, search]);
          setSelectedValue(search);

          console.log("setting value to = " + search);

          // Adding next as a 2nd pass. Kosher?
          // addNewData.mutate({
          mutate({ name: search });
        } else {
          console.log("setting value & search to " + val);
          setSelectedValue(val);
          setSearch(val);
        }

        console.log("After test, val (may not be updated) = " + val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedValue || "");
          }}
          placeholder="Search value"
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah={200}>
            {options}
            {!exactOptionMatch && search.trim().length > 0 && (
              <Combobox.Option value="$create">
                + Create {search}
              </Combobox.Option>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
