import { useQueryClient, useMutation, QueryKey } from "@tanstack/react-query";
import { deleteProductById, deleteProfileByTypeAndId } from "../util/http";
import { useNotificationStore } from "../store/useNotificationStore";
import { useLoginStore } from "../store/useLoginStore";


/**
 * 
 * Is the queryKey "customers" or "customerProfile" ??
 * 
 */
const calculateQueryKey = ( {
    profileType,
  } : {
    // profile  type "customers" or "managers"
    profileType: string,
  } ) => {
    let queryKey : string;
    switch (profileType) {
      case "customers":
        queryKey = "customerProfile";
        break;
      case "managers":
        queryKey = "managerProfile";
        break;
      default:
        queryKey = "adminProfile";
    }
    return queryKey;
 };

export const useProfileDelete = () => {
  // Access the react query singleton client
  const queryClient = useQueryClient();
  const notificationStore = useNotificationStore();
  const { accessToken } = useLoginStore();

  // Alias the reference of the mutate function pointer to "profileDelete",
  // rather than "mutate"
  const { mutate: profileDelete, data: returnedData, isPending } = useMutation({
    // Q. Why this error? "Type '(profileType: string, profileId: number) => Promise<string>' is not assignable to type 'MutationFunction<string, string>'.""
    // A. useMutation can only accept one parameter. If you need multiple parameters, please use an object.
    // Source: https://stackoverflow.com/questions/72933996/react-query-usemutation-is-not-assignable-to-parameter-of-type-mutationkey    
    mutationFn: (profile: { profileType:string, profileId: number}) => 

    deleteProfileByTypeAndId({profileType: profile.profileType, profileId: profile.profileId, accessToken: accessToken || "" }),
    
    onSuccess: (data) => {
        const queryKey = calculateQueryKey({profileType: data.profileType});
        // call query client to force a refresh of the query, so we'll get an
        // immediate refresh
        console.log("Success in deleting " + data.profileId);
        notificationStore.successNotification("Successfully deleted profile for " + data.profileId);
        queryClient.invalidateQueries({queryKey: [queryKey]});
        console.log("invalidated the cache for " + queryKey);      
    },
    onError: (data: Error) => {
      notificationStore.errorNotification(
        data.message,
        "Failed to delete profile."
      );
    },
  });
  return { profileDelete, isPending };
};
