import { Title } from "@mantine/core";
import { useActiveCategoryStore } from "../../store/useActiveCategoryStore";

const ActiveCategory = () => {
    const { activeCategory, setActiveCategory } = useActiveCategoryStore();

    return (
        <Title order={2} mb={"md"} ml={"sm"} >
        {activeCategory ? activeCategory.name : "All products"}
      </Title>
    );
}

export default ActiveCategory;