import { useQuery } from "@tanstack/react-query";
import { fetchGraphByTypeAndFilename } from "../util/http";
import { GRAPH_TYPE_TABLEAU_PREP } from "../types/constants";
import { CompoundVertex, VerticesAndEdges } from "../types/interfaces";

export const useTableauPrepQueryWithFilename  = (filename: string) => {
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["filename-tableau-prep", filename],

      queryFn: ({ signal }) =>
        fetchGraphByTypeAndFilename<CompoundVertex[]> ({signal, 
          graphType: GRAPH_TYPE_TABLEAU_PREP,
          filename, 
          suppressUi: true }),        
    });
      
    return { data, isLoading, error };
  };