import { useQuery } from "@tanstack/react-query";
import { fetchNodeNames } from "../util/http";

export const useNodeNameQuery  = (filename: string) => {
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["filename", filename],
  
      queryFn: ({ signal }) =>
        fetchNodeNames ({signal, filename }),
    });
  
    return { data, isLoading, error };
};
