// cytoscapeStyles.ts
import cytoscape, { Stylesheet } from 'cytoscape';
import chroma from 'chroma-js';

// Define your color scale outside of the styles to be used in the background-color function
export const scale = chroma.scale(['#fafa6e','#2A4858']).mode('lch').colors(10);

// A function to correctly type the background color calculation
const backgroundColorFn: cytoscape.Css.MapperFunction<cytoscape.NodeSingular, string> = (node) => {
    const level = node.data('level');
    if(node.data('clicked')) {
      return 'blue'; // return blue if the node is marked as clicked
    } else if (level === 0) {
      return 'red';
    } else if (level > 0 && level <= scale.length) {
      return scale[level];
    } else {
      return '#2CCCE4';
    }
  };

 const edgeColorFn: cytoscape.Css.MapperFunction<cytoscape.EdgeSingular, string> = (edge) => {
  const edgeType = edge.data('type');
    
    switch (edgeType) {
        case 'nested':
            return 'green';
        case 'reference':
            return 'red';
        default:
            return '#ccc'; // Fallback color if no type matches
    }
};  

//export const styles: Stylesheet[] = [  
export const getStyles = () : Stylesheet[] => [
  {
    selector: 'node',
    style: {
      'label': 'data(label)',
      'shape': 'rectangle',
      'width': 100,
      'height': 60,
      'background-color': backgroundColorFn,
      'text-wrap': 'wrap',
      'text-valign': 'center',
      'text-halign': 'center',
      "font-family": "Helvetica",
      "font-size": 12,
      "font-style": "normal",
      "font-weight": "normal",
      "color": "#000",
      'text-outline-color': '#37a',
      "background-image-opacity": 1,
      "background-opacity": 0.5,
    }
  },
  /**
   * Hard earned lesson: node:parent must appear below node, or it will
   * be ignored.
   */
  {
    selector: 'node:parent',
    style: {
        'font-weight': 'bold', // Make the text bold
        // @ts-ignore        
        'text-decoration': 'underline', // Underline the text
        'background-color': 'transparent', // Makes the node background transparent
        'background-opacity': 0, // Need not be set if background color is transparent
        'border-width': 2, // Adjust the width as needed
        'border-color': 'black', // Choose a visible color for the border
        //'padding': '10px', // Provide padding to create space between the children and the border
        'compounds-padding': '10px', // Extra space around compound nodes (parent nodes)
        // Additional styles for labels or other features if needed

        //'background-opacity': 0.333,
        //'background-color': 'red',
        // @ts-ignore
        //'padding': '10px', // Padding added here to separate parent and child nodes
        'text-valign': 'top', // Aligns text vertically at the top of the node
        // @ts-ignore
        //'text-margin-y': '-10px', // Adjusts the margin to nudge the label above the node
        'text-halign': 'center' // Centers the label horizontally      

    }
  },
  
  {
    selector: 'edge',
    style: {
      'curve-style': 'bezier',
      'line-color': edgeColorFn, // Use edgeColorFn to dynamically set the line color based on edge data      
      'target-arrow-shape': 'triangle',
      'target-arrow-color': '#ccc'
    }
  },
  // Styling for core interactions
  // Core styling to suppress the gray circle interaction visual
  // Suppress gray circles in viewport when a mouse is clicked.
  // It makes it look amateurish when you click on the compass rose
  // arrows and a gray circle appears under the component. If I 
  // move the compass rose (CytoscapeControls.tsx) out of the Cytoscape
  // viewport, this code isn't necessary. 
  // Note: I had to put in the ts-ignore annotation because 
  // typescript complains. But it seems to work.
  {
    selector: 'core',
    // @ts-ignore      
    style: {
      'active-bg-opacity': 0,
    }
  }  
];