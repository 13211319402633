import { useMutation } from "@tanstack/react-query";
import { FileUploadBody } from "../types/interfaces";
import { useLoginStore } from "../store/useLoginStore";
import { uploadFiles } from "../util/http";
import { useNotificationStore } from "../store/useNotificationStore";

export const useFileUpload = () => {
    const { accessToken } = useLoginStore();
    const notificationStore = useNotificationStore();

    const { mutate: uploadFile, reset, isSuccess, isPending,isError } = useMutation({
        mutationFn: (requestBody: FileUploadBody) =>
            uploadFiles({requestBody, accessToken: accessToken || "" }),
        
        onSuccess: (data) => {
            notificationStore.successNotification(data.message, "Upload success");
        },
        onError: (data) => {
            notificationStore.errorNotification(data.message, "Upload failure");
        },
    });

    return {uploadFile, reset, isSuccess,isPending,isError };
}