import React from "react";
import { Outlet } from "react-router-dom";
import { Container, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { theme } from "../theme/theme";

// Per: https://stackoverflow.com/questions/75785717/i-am-using-createbrowserrouter-what-is-the-proper-way-to-have-header-and-footer
// and: https://reactrouter.com/en/main/upgrading/v6-data#differences

// Inter is the fontFamily that comes with it.
// For Mantine v 7, withGlobalStyles and withNormalizeCSS
// appear gone.
// I added defaultColorScheme. Setting it to "dark" and the
// left sidebar is still white. Interesting.
//     {/*      <MantineProvider defaultColorScheme="light">*/}
const CoreWrapper = () => {
  return (
    <MantineProvider theme={theme}>
      <Container size="responsive" bg="var(--mantine-color-blue-light)">
      <Notifications autoClose={10000} />
        <ModalsProvider>
          <Outlet />
        </ModalsProvider>        
      </Container>
    </MantineProvider>    
  );
};

export default CoreWrapper;
