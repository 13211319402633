import { useLocation } from 'react-router-dom';
import CytoscapeControlPanel from '../components/graph/CytoscapeControlPanel';
import { zoomInHandler } from '../components/graph/cytoscapeHandlers';
import { HeaderConfig } from '../types/interfaces';

import { ReactElement, useState } from 'react';
import CytoscapeControls from '../components/graph/CytoscapeControls';
import React from 'react';
import HeaderSimple from './HeaderSimple';
import ControlButton from '../components/buttons/ControlButton';
import { IconChevronLeft } from '@tabler/icons';
import { useRefStore } from '../provider/AppProvider';
import { Core } from 'cytoscape';
import { CYTOSCAPE_INSTANCE_KEY } from '../types/constants';
import { Button } from '@mantine/core';

// This regular expression will capture the segment after "/app/"
// For example, it matches "/app/file/", "/app/settings/", etc.
const routePattern = /^\/app\/([^\/]+)\/.*/;



const headerConfig = (pathname: string) => {
  // Get next three out of here. Only while I experiment with putting the cytoscape
  // drawer in the header. If I like it, create another component. It shouldn't be
  // in the generic header logic
    const [drawerOpened, setDrawerOpened] = useState(false);
    const { refStore } = useRefStore<Core>(); // Specify the generic parameter 
    // Now access the specific cyRef using the key you've set
    const cyRef = refStore[CYTOSCAPE_INSTANCE_KEY];      

    console.log("headerConfig, will attempt to match " + pathname);
    const routePattern = /^\/app\/([^\/]+)\/.*/;
    const match = pathname.match(routePattern);
    console.log("regexp match " + match);
    if (match && match[1]) {
        const segment = match[1];
        console.log("match[1] = X" + segment +"X");
        switch (segment) {
            
          case 'file':
          case 'tableau-prep':
            console.log("The string matches file");
            const panel =<CytoscapeControls/>;
            // There's no reason to think it's null, but let's see. Perhaps
            // more for instructional purposes for the future me.
            // Directly check if the component is not null here, if needed
            /*
            if (React.isValidElement(panel)) {
              // Component is valid and not null
              return (
                <HeaderSimple>
                  <GraphControlsDrawer isOpen={drawerOpened} onClose={() => setDrawerOpened(false)} /> 
                    onClick={() => cyRef && setDrawerOpened(true)}
                    // Place the button somewhere that makes sense in your layout, such as the top-right
                    style={{ position: 'absolute', top: 10, left: 10 }}
                  >
                    Open Graph Controls
                  </Button>     
                  {panel}
                  </HeaderSimple>
              )
            } else {
              // Component is null or not valid, handle accordingly
              return (<div>Error loading the CytoscapeControls component.</div>);
            }       
            */
                   
          default: 
                console.log("This header area for sale");
                return null;                
        }
    }
  // Define other paths and their respective headers if needed
  // '/some-other-page': <SomeOtherHeaderComponent />,
  return null;
};

export default headerConfig;