import { useLoginStore } from "../store/useLoginStore";

import {
  Authority,
  Category,
  FileUploadBody,
  ProcessedFile,
  Product,
  User,
} from "../types/interfaces";
import { AddProductRequest } from "../components/forms/AddProductForm";
import { UpdateProductRequest } from "../pages/product-details/ProductDetailsAdminManagerView";

import authorizedFetch from "../services/authorizedFetch";

// Converting from ./src/hooks/useCategoriesQuery,
// to something that looks like the Udemy instructor's
// new-24-tanstack-query lesson.
// In this example, rather than inferring the return type, I'm explicitly
// telling it that it's a Promise of an array of categories. The return type is not required.
// https://github.com/typescript-cheatsheets/react#reacttypescript-cheatsheets
// "you can choose annotate the return type so an error is raised if you accidentally return some other type"

export const fetchCategories = async ({
  signal
}: {
  signal: AbortSignal;
}): Promise<Category[]> => {

  const response = await authorizedFetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/categories`,
    {
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the categories");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  const categories: Category[] = await response.json();
  return categories;
};

// Takes the category name as a string, converts it into JSON and
// sends it to the server. The server is depending upon the JSON looking like:
//
// {"name":"My custom category"}
//
// Specifically, there has to be a property with the name of "name"
// See the CategoryController's NewCategoryBody record type.
export const addNewCategory = async ({
  requestBody,
  accessToken,
}: {
  requestBody: { name: string };
  accessToken: string;
}) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/categories`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while adding a new category");
    error.code = response.status;
    error.message = await response.json();
    console.log("Error: " + error.message);
    throw error;
  }

  const category: Category = await response.json();

  return category;
};

// Fetch all of the products in a category.
// If no category is active, get them all..
// Zustand is handling our state.

export const fetchProducts = async ({
  enabled = true,
  signal,
  accessToken,
  categoryId,
}: {
  enabled: boolean;
  signal: AbortSignal;
  accessToken: string;
  categoryId: number;
}): Promise<Product[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/products?name=${categoryId}`,
    {
      headers,
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the products");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  const products: Product[] = await response.json();

  return products;
};

// Fetch one product by product ID.
export const fetchProductById = async ({
  signal,
  accessToken,
  productId,
}: {
  signal: AbortSignal;
  accessToken: string;
  productId: number;
}): Promise<Product> => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/products/${productId}`,
    {
      headers,
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the images");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  console.log("Inside fetchProductById");
  const product: Product = await response.json();
  return product;
};

// The udemy example didn't have a delete method. Flying even more by
// the seat of our pants :).

export const deleteProductById = async ({
  productId,
  accessToken,
}: {
  productId: number;
  accessToken: string;
}) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/products/${productId}`,
    {
      method: "DELETE",
      headers,
    }
  );
  // Notice: the Java ProductController after deleting the ID was
  // then re-querying the database for the records. Isn't this what
  // react-query does for us? So rather than do it in the Java,
  // the product controller is now deleting the record & returning void.
  // No productRepository.findAll() after the deleteById(id)
  if (!response.ok) {
    const error = new Error("An error occurred while deleting the product");
    error.code = response.status;
    // Hmm. Will the message be JSON now that the Java returns void?
    error.message = await response.json();
    throw error;
  }

  //  return the response object
  return response;
};

export const addNewProduct = async ({
  productRequest,
  accessToken,
}: {
  productRequest: AddProductRequest;
  accessToken: string;
}) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  console.log(
    "addNewProduct productRequest =" + JSON.stringify(productRequest)
  );
  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/products`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(productRequest),
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while adding a new product");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  const { product } = await response.json();

  return product;
};

export const updateProduct = async ({
  requestBody,
  productId,
  accessToken,
}: {
  requestBody: UpdateProductRequest;
  productId: number;
  accessToken: string;
}) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/products/${productId}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    const error = new Error(
      `An error occurred while updating the product ${requestBody.name}`
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  const product: Product = await response.json();
  return product;
};

export const fetchProfiles = async ({
  profile,
  signal,
  accessToken,
}: {
  profile: string | undefined;
  signal: AbortSignal;
  accessToken: string;
}) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/${profile}s`,
    {
      headers,
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error(
      "An error occurred while fetching the " + profile + " profiles"
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  // Might return a User or a Customer. Seems like typescript doesn't complain
  // if I don't specify the returning type, so let's go for it.
  return await response.json();
};

/* ADT: Notice my attempt at using generics here, to take in either
 * a "User" or a "Customer".
 * Followed the pattern here:
 * https://www.freecodecamp.org/news/typescript-generics-with-functional-react-components/
 *
 * and for the "extends User", so we can refer to "requestBody.name"
 *
 * https://stackoverflow.com/questions/62437690/property-id-does-not-exist-on-type-t-2339-typescript-generics-error
 */

export const updateProfile = async <T extends User>({
  requestBody,
  authority,
  accessToken,
}: {
  requestBody: T;
  authority: Authority | null;
  accessToken: string;
}) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/${authority?.toLowerCase()}s`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    const error = new Error(
      `An error occurred while updating ${requestBody.name}'s profile`
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  //const user: User = await response.json();
  const user: T = await response.json();

  return user;
};

export const deleteProfileByTypeAndId = async( {
  profileType,
  profileId,
  accessToken,
} : {
  // profile  type "customers" or "managers"
  profileType: string,
  profileId: number,
  accessToken: string;
} ) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };  

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/${profileType}/${profileId}`,
    {
      method: "DELETE",
      headers,
    }
  ); 
  
  if (!response.ok) {
    const error = new Error(
      `An error occurred while deleting the ${profileType} ${profileId}`
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }  
  return {profileType:profileType, profileId: profileId};
}

/**
 * Get all customers
 * 
 */
export const fetchCustomers = async( {
  signal,
  accessToken,
} : {
  signal: AbortSignal;
  accessToken: string;
} ) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };  

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/customers/all`,
    {
      method: "GET",
      headers,
    }
  ); 
  
  if (!response.ok) {
    const error = new Error(
      "An error occurred while fetching all customers"
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }  
  return await response.json();
}


/**
 * 
 * Delete ones own account, by the accessToken. On the 
 * backend, it gets converted to the security Principal's email address
 * 
 * @param param0 
 * @returns 
 */
export const deleteCustomerByPrincipal = async( {
  accessToken,
} : {
  accessToken: string;
} ) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };  

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/customers`,
    {
      method: "DELETE",
      headers,
    }
  ); 
  
  if (!response.ok) {
    const error = new Error(
      "An error occurred while deleting your account"
    );
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }  
  return await response.json();
  
}

export const uploadFiles = async({
  requestBody,
  accessToken
  }: {
  requestBody: FileUploadBody;
  accessToken: string;
  }) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const data = new FormData();
  
  requestBody.files?.map((file) => {    
    data.append('files', file, file.name)
  });

  data.append('notes', requestBody.notes);
  data.append('overwrite', new Boolean(requestBody.overwrite).toString())
  
  console.log("files = " + [requestBody.files]);
  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/upload`,
    {
      method: "POST",
      headers,
      body: data,
    }
  );

  if (!response.ok) {
    const err = await response.json();
    const error = new Error(
      err.message ||'An error occurred while uploading files'
    );

    error.code = response.status;
    throw error;
  }
  return  await response.json();
 }


 /**
  * 
  * Get all of a user's processed files.
  * @param param0 
  * @returns 
  */
 export const fetchFiles = async ({
  enabled = true,
  signal,
  accessToken
}: {
  enabled: boolean;
  signal: AbortSignal;
  accessToken: string;
}): Promise<ProcessedFile[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/files`,
    {
      headers,
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the files");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }

  const files: ProcessedFile[] = await response.json();

  return files;
};

/**
 * Note: Googled with useQuery seems to run twice. 
 * Per: https://github.com/TanStack/query/issues/3633:
 * 
 * Problem:  If you pass the signal parameter, the api call runs 
 *           twice (cancelling the first) on page reload.
 * 
 * Solution: This is the expected behaviour, or at least, there is not a lot
 *           we can do about it. This is related to strict effects in react 18, which
 *           means you'll only see this behaviour if you use <StrictMode>, and only 
 *           during development. In that mode, react quickly mounts and unmounts every
 *           component to stress-test the app and to simulate quick user interaction. 
 * 
 *           If you don't consume the AbortSignal, the second fetch will not fire
 *           because the first one is still ongoing. You can read more about this here:
 *           facebook/react#24502 (comment).
 * 
 *           graphType is either 'graph' or 'tableau-prep`
 * 
 * @param param
 * @returns 
 */
export const fetchGraphByTypeAndFilename = async<T> ({
  signal,
  graphType,
  filename,
  suppressUi
}: {
  signal: AbortSignal;
  graphType: string;
  filename: string;
  suppressUi: boolean
}): Promise<T> => {

  const response = await authorizedFetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/${graphType}/${filename}/${suppressUi}`,
    {
      // Per my note above, don't consume the signal ?
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the graph of the file");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  console.log("Inside fetchGraphByFilename");
  const graph: T = await response.json();
  return graph;
};


export const fetchGraphByRootIdAndFilename = async<T> ({
  signal,
  filename,
  node, 
  generations
}: {
  signal: AbortSignal;
  filename: string;
  node: string;
  generations: number
}): Promise<T> => {

  const response = await authorizedFetch(
   `${import.meta.env.VITE_APP_BACKEND_URL}/graph/root/${filename}/${node}/${generations}`,
    {
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the graph of the file by the root ID");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  console.log("Inside fetchGraphByRootIdAndFilename");
  const graph: T = await response.json();
  return graph;
};

export const fetchGraphCompoundNodesByFilenameAndNodeId = async<T> ({
  signal,
  filename,
  node, 
  generations,
  options  
}: {
  signal: AbortSignal;
  filename: string;
  node: string;
  generations: number,
  options: {
    ancestors: boolean,
    descendants: boolean
  }  
}): Promise<T> => {

const baseUrl = `${import.meta.env.VITE_APP_BACKEND_URL}/graph/compound/id/${filename}/${node}/${generations}`;

// Initialize URLSearchParams
const queryParams = new URLSearchParams();


// Conditionally add 'parents' and 'children' parameters based on 'ancestors' and 'descendants'

if (options.ancestors) {
  queryParams.append('parents', 'true');
}
if (options.descendants) {
  queryParams.append('children', 'true');
}
  

// Construct the final URL with query parameters
const urlWithParams = `${baseUrl}?${queryParams}`;

console.log("urlWithParams = ", urlWithParams);


  //console.log("hitting authorized fetch...");
  const response = await authorizedFetch(urlWithParams,
    {
      signal,
    }
  );


  if (!response.ok) {
    const error = new Error("An error occurred while fetching the graph of the file by the root ID");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  //console.log("Inside fetchGraphCompoundNodesByFilename");
  const graph: T = await response.json();
  return graph;
};

//getAllCompoundVerticesByRootName
export const fetchGraphCompoundNodesByFilenameAndNodeName = async<T> ({
  signal,
  filename,
  node, 
  keyword,
  generations,
  options
}: {
  signal: AbortSignal;
  filename: string;
  node: string;
  keyword: string;
  generations: number,
  options: {
    ancestors: boolean,
    descendants: boolean
  }   
}): Promise<T> => {



  const baseUrl = `${import.meta.env.VITE_APP_BACKEND_URL}/graph/compound/name/${filename}/${generations}`;

  //  console.log("hitting authorized fetch...");
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams();

  // Conditionally add 'node' if it exists
  if (node) {
    queryParams.append('name', node);
  }

  // Conditionally add 'keyword' if it exists
  if (keyword) {
    queryParams.append('keyword', keyword);
  }

  // Conditionally add 'parents' and 'children' parameters based on 'ancestors' and 'descendants'
  if (options.ancestors) {
    queryParams.append('parents', 'true');
  }
  if (options.descendants) {
    queryParams.append('children', 'true');
  }

  // Construct the final URL with query parameters
  const urlWithParams = `${baseUrl}?${queryParams}`;

  // Proceed with the fetch using the constructed URL
  const response = await authorizedFetch(urlWithParams, {
    signal,
  });

  if (!response.ok) {
    // Parse the JSON to get the detailed error message
    const errorResponse = await response.json();
    // Construct a specific error message or use a generic one if parsing fails
    const errorMessage = errorResponse.message || "An unspecified error occurred while fetching the graph data.";
    // Throw a new error with the specific or fallback message
    throw new Error(errorMessage);
  }

  const graph: T = await response.json();
  return graph;
};


export const fetchMermaidByFilename = async ({
  signal,
  filename
}: {
  signal: AbortSignal;
  filename: string
}): Promise<string> => {
    
  const response = await authorizedFetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/tableau-prep/mermaid/${filename}`,
    {
      // Per my note above, don't consume the signal ?
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the mermaid diagram of the file");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  console.log("Inside fetchMermaidByFilename");
  const diagram: string = await response.text(); // Use response.text() for plain string response
  return diagram;
};

export const fetchFlowByFilename = async ({
  signal,
  filename
}: {
  signal: AbortSignal;
  filename: string
}): Promise<string> => {
    
  const response = await authorizedFetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/tableau-prep/flow/${filename}`,
    {
      // Per my note above, don't consume the signal ?
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the file's flow document");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  console.log("Inside fetchFlowByFilename");
  const document: string = await response.text(); // Use response.text() for plain string response
  return document;
};



//------
export const fetchNodeNames = async ({
  signal,
  filename,
}: {
  signal: AbortSignal;
  filename: string;
}): Promise<string[]> => {
  const response = await authorizedFetch(
    `${import.meta.env.VITE_APP_BACKEND_URL}/node/name/${filename}`,
    {
      signal,
    }
  );

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the node names");
    error.code = response.status;
    error.message = await response.json();
    throw error;
  }
  return await response.json();
};
