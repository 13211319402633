import React from 'react';
import { Box, Divider, Grid, Paper } from '@mantine/core';
import CytoscapeControlPanel from './CytoscapeControlPanel'; // Assuming this is your arrow controls component
import SearchGraphNodeForm from '../forms/SearchGraphNodeForm';
import GraphColorPicker from './GraphColorPicker';

const FooterFormAndControls = ({ 
    filename,
    onSubmit
} : {
    filename: string;
    onSubmit: (nodeName: string, keyword: string, generations: number,
        options: { ancestors: boolean, descendants: boolean, clearViewport: boolean }) => void;
}) => {

  // Function to handle changing node colors based on a label name
  const handleColorChange = () => {
    // Implement logic for changing node colors using the color state
  };

  return (
    <Paper shadow="sm" radius="md" style={{ padding: '10px', margin: '10px' }}>
        <Box
            style={{ height: 'var(--app-shell-footer-height)',
            width: 'calc(100hh - var(--app-shell-navbar-width))',
            backgroundColor: '#f0f0f0', // Use a fixed color or get it from theme.colors.gray[0]
        }}
        >
        <Grid gutter="xs">
            <Grid.Col span={8} style={{ display: 'flex', alignItems: 'center' }}>            
            {/* Search Graph Node Form inside a Box to constrain its max height */}
                <SearchGraphNodeForm filename={filename} onSubmit={onSubmit} />
                <Divider orientation="vertical" size="sm" style={{ margin: '0 20px' }} /> {/* Margin for visual space */}                                            
            </Grid.Col>
            <Grid.Col span={4}> {/* Rest of the columns (total should add up to 12) */}
                {/* Cytoscape Control Panel (Arrow Controls) */}
                <CytoscapeControlPanel />
            </Grid.Col>
        </Grid>
        </Box>
    </Paper>
  );
};

export default FooterFormAndControls;