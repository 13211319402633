import React, { useEffect, useRef, useState } from 'react';
import { useToggle } from '@mantine/hooks';

import cytoscape, { EventObject } from 'cytoscape';

// npm i --save-dev @types/cytoscape-klay
import klay from 'cytoscape-klay';

import { CytoscapeGraphProps  } from '../../types/interfaces';
import { addCompoundNodesToGraph  } from './graphUtils';
import { getStyles } from './cytoscapeStyles';
import { layoutOptions } from './cytoscapeLayout'; // path to your layoutConfig.ts file
import { ActionTypes } from '../../types/enums';
import { CYTOSCAPE_INSTANCE_KEY } from '../../types/constants';
import { useRefStore } from '../../provider/AppProvider';

import { useCytoscapeLayout } from '../../hooks/useCytoscapeLayout';
import ErrorIndicator from '../common/ErrorIndicator';
import LoadingIndicator from '../common/LoadingIndicator';

// register the extension
cytoscape.use(klay);


// CytoscapeGraph component expecting data and handlers from props
// Note: "onNodeContextMenu" means a right-click
//const CytoscapeGraph = ({ data, onNodeClick, onNodeContextMenu,clearViewport, onClearComplete,isLoading, error }:
const CytoscapeGraph = ({ data,
  onNodeClickFetchData,
  clearViewport,
  onClearComplete,
  shouldFit,
  setShouldFit,
  onLayoutStop,
  isLoading,
  error }:
      CytoscapeGraphProps
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    //const [shouldFit, setShouldFit] = useToggle<boolean>();
    // Using a counter, which changes.  The actual value isn't important, just that
    // it triggers a change for the effect. It could also be a toggle that goes between
    // on and off.
    const [layoutModified, setLayoutModified] = useState(0); 
  
    // Note: The usage of null as any is not ideJal and is considered a
    // workaround for the purpose of type assertion. It can bypass the TypeScript 
    // compiler's strict checks, so you should use this with caution only if there's
    // no other way to resolve the type correctly according to your application logic.
    const cyRef = useRef<cytoscape.Core>(null as any); // Using 'as any' to assert the type    
    // From our custom AppProvider
    const { dispatch } = useRefStore<cytoscape.Core>();

    console.log("From parent shouldFit ", shouldFit);

    // Define a callback in the parent component
    const handleLayoutStop = () => {
      setShouldFit(false);
    };

    useCytoscapeLayout(cyRef, layoutOptions, shouldFit,layoutModified,onLayoutStop);
   
    /**
     * Next is necessary to put the compass rose at the top right of the
     * Cytoscape viewport.
     */
    const cyContainerStyle: React.CSSProperties = {
      position: 'relative', // This makes it the reference for absolutely positioned children
      width: '100%',
      height: 'calc(100vh - var(--app-shell-header-height) - var(--app-shell-footer-height))', // Subtract header and footer heights from 100vh      
    };    

    /**
     * Initialize the graph on component mount and add new data when it's available.
     * This first `useEffect` is focused solely on setting up the
     * graph and reacting to changes in data by updating the graph's nodes and edges.
     * Important note: "containerRef.current" is the div:
     * 
     *    <div class=" __________cytoscape_container" style="width: 80%; height: 850px;">
     * 
     * It must be rendered for anything to happen. Previously I was only conditionally rendering
     * it when isLoading was false. If isLoading was true, then it wouldn't render and thus
     * this useEfect wasn't getting triggered when the page loaded.
     * 
     */
 
    // Effect for initializing 'cy' instance. Runs once the div is available.
    useEffect(() => {
      if (containerRef.current && !cyRef.current) {
        // First time initialization
        const cyInstance = cytoscape({
          container: containerRef.current,
          style: getStyles(), // Reference the getStyles function to set styles          
        });
        cyRef.current = cyInstance;   

        // Save the cyRef in our custom AppProvider so that we can
        // access it elsewhere (the header + the controls)
        dispatch({ type: ActionTypes.SetRef, key: CYTOSCAPE_INSTANCE_KEY, ref: cyRef });
      } 
      return () => {
        dispatch({ type: ActionTypes.RemoveRef, key: CYTOSCAPE_INSTANCE_KEY });        
        // Clean up the Cytoscape instance on unmount
        cyRef.current?.destroy(); // Conditional chaining ensures safety
      };      
    }, []); // Empty dependency array - dispatch does not need to be listed      
   
    // Update logic: run whenever 'data' changes and cyRef.current is available
    useEffect(() => {
      console.log("The data has changed inside CytoscapeGraph. useEffect for data triggered ");

        if (data && cyRef.current) {
          addCompoundNodesToGraph(data, cyRef.current);
          setLayoutModified(prev => prev + 1); // Increment to indicate graph modification. THe actual value is meaningless          

          // Fit on the first retrieval to put the nodes in the viewport. Otherwise
          // for whatever reason, they're a little to the left and a little too
          // far up, and all of the nodes aren't visible.
          
          // Call 'fit' only after the initial render
        }
    }, [data]); //, addNodesToGraph]);    
    /* End of useEffect hook for data */
 
    /**
     * This third `useEffect` is dedicated to user interactions: it sets up the
     * event handler for node clicks. When a user clicks on a node, the event handler
     * decides whether to fetch new child data (if not fetched before) or toggle the
     * visibility of current children (if already fetched).
     * 
     * 
     * Attach event handlers to Cytoscape nodes
     * Handler for clicking on nodes
     * Event listener logic: run whenever cyRef.current changes
     */


    const handleNodeClickFetchData = (node: cytoscape.NodeSingular, displayType: 'ancestors' | 'descendants') => {
      const cyInstance = cyRef.current;
      if (!cyInstance) return;
    
      //const node = event.target;
      const nodeId = node.id();
      // Mark the currently clicked node
      node.data('clicked', true);      
    
      onNodeClickFetchData(nodeId, displayType);
    }
    

   /**
     * This third `useEffect` is dedicated to user interactions: it sets up the
     * event handler for node clicks. When a user clicks on a node, the event handler
     * decides whether to fetch new child data (if not fetched before) or toggle the
     * visibility of current children (if already fetched).
     * 
     * 
     * Attach event handlers to Cytoscape nodes
     * Handler for clicking on nodes
     * Event listener logic: run whenever cyRef.current changes
     */
/*NIGHT commenting out for the moment to experiment with left & right side clicking
    useEffect(() => {
      const cyInstance = cyRef.current;
    
      if (cyInstance) {
        cyInstance.on('tapend', 'node', handleNodeClick);
    
        return () => {
          cyInstance.off('tapend', 'node', handleNodeClick);
        };
      }
    }, [onNodeClick]);
*/


useEffect(() => {
  const cyInstance = cyRef.current;
  if (cyInstance) {
    const detailedNodeClickHandler = (event:EventObject) => {
      const node = event.target;
      const clickedPositionX = event.position.x; // X position of the click
      const nodePositionX = node.position('x'); // X position of the node center
      const nodeWidth = node.width();
      
      // Calculate the center X position of the node
      const centerX = nodePositionX;
      
      // Determine if the click was on the left or right half of the node
      if(clickedPositionX < centerX) {
        // Logic for handling click on the left half
        console.log("left side click, will invoke 'ancestors'");
        handleNodeClickFetchData(node, 'ancestors');
        //handleDisplayAncestors(node);
      } else {
        // Logic for handling click on the right half
        console.log("right side click, will invoke 'descendants'");
        //handleDisplayDescendants(node);
        handleNodeClickFetchData(node, 'descendants');
      }
    };

    cyInstance.on('tapend', 'node', detailedNodeClickHandler);

    return () => {
      cyInstance.off('tapend', 'node', detailedNodeClickHandler);
    };
  }
//}, [handleDisplayAncestors, handleDisplayDescendants]); // Assuming these are defined outside and passed as props or context
 }, [handleNodeClickFetchData]); // Assuming these are defined outside and passed as props or context

 

    // useEffect hook to clear the viewport when clearViewport is true
    useEffect(() => {
      const cyInstance = cyRef.current;
      
      if (clearViewport && cyInstance) {
        // This removes all elements (nodes and edges) from the graph
        cyRef.current.elements().remove();

        // After clearing the graph, reset clearViewport state
        onClearComplete(); // You would need to add this prop and call it in the parent component
      }
    }, [clearViewport]); // Only re-run the effect if clearViewport changes

    
  /**
   * Note: 
   * The id attribute (ie div id='cy') is not required for the functioning
   * of Cytoscape within a React component as long as you're using the ref
   * to reference the container. Typically, in a React application, you would
   * access DOM elements using refs instead of ids to maintain consistency with
   * React's way of handling the DOM.
   */

   
  return (
     <div ref={containerRef} style={cyContainerStyle} > 
        {isLoading && <LoadingIndicator />}
        {error && <ErrorIndicator error={error} />}     
      </div>      
  );
}

export default CytoscapeGraph;


