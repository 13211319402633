import { useQuery } from "@tanstack/react-query";
import { useActiveCategoryStore } from "../store/useActiveCategoryStore";
import { useLoginStore } from "../store/useLoginStore";
import { fetchProductById, fetchProducts } from "../util/http";

export const useProductQuery = (enabled: boolean = true) => {
  // "object destructuring" is pulling out one of the return values,
  // enclosing it/them in curly braces.
  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  const { activeCategory } = useActiveCategoryStore();
  const categoryId = activeCategory ? activeCategory.id : 0;

  return useQuery({
    queryKey: ["products", activeCategory],

    queryFn: ({ signal }) =>
      fetchProducts({
        enabled,
        signal,
        accessToken: token,
        categoryId,
      }),
    enabled,
  });
};

export const useProductQueryWithId = (productId: number) => {
  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  const { data, isLoading } = useQuery({
    queryKey: ["products", productId],

    queryFn: ({ signal }) =>
      fetchProductById({ signal, accessToken: token, productId }),
  });

  return { data, isLoading };
};
