/*
  const deleteCustomerMutation = useMutation({
    mutationFn: (customerId: number) => {
      return axios.delete<Customer[]>(
        `${import.meta.env.VITE_APP_BACKEND_URL}/customers/${customerId}`
      );
    },
    onSuccess: (data) => {
      notificationStore.successNotification("Deleted customer successfully");
      queryClient.setQueryData(["customers"], data);
    },

    onError: (data: AxiosError) => {
      notificationStore.errorNotification(
        data.message,
        "Cannot delete customer"
      );
    },
  });
*/

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationStore } from "../store/useNotificationStore";
import { useLoginStore } from "../store/useLoginStore";
import { deleteCustomerByPrincipal, deleteProfileByTypeAndId } from "../util/http";
import { useNavigate } from "react-router-dom";

/**
 * Allows a customer to delete their own profile/account. On the backend,
 * it deletes by extracting the Principal out from the passed in 
 * accessToken
 */
export const useCustomerDelete = () => {
    // Access the react query singleton client
    const queryClient = useQueryClient();
    const notificationStore = useNotificationStore();
    const { accessToken,logoutUser } = useLoginStore();
    const navigate = useNavigate();

  
    // Alias the reference of the mutate function pointer to "customerDelete",
    // rather than "mutate"
    const { mutate: customerDelete, data: returnedData, isPending } = useMutation({
      mutationFn: () => 
        deleteCustomerByPrincipal( {accessToken:accessToken || ""}),
      
      onSuccess: (returnedData) => {
          console.log("Success in deleting " + returnedData.email);
          notificationStore.successNotification("Successfully deleted profile for " + returnedData.email);
          queryClient.invalidateQueries({queryKey: ["customerProfile"]});
          logoutUser();
          navigate("/");          
          
      },
      onError: (data: Error) => {
        notificationStore.errorNotification(
          data.message,
          "Failed to delete profile."
        );
      },
    });
    return { customerDelete, isPending};
  };
  