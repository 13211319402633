import { useQuery } from "@tanstack/react-query";
import { fetchMermaidByFilename } from "../util/http";

export const useTableauPrepMermaidQueryWithFilename  = (filename: string) => {
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["mermaid-filename-tableau-prep", filename],

      queryFn: ({ signal }) =>
        fetchMermaidByFilename ({signal, 
          filename }),        
    });
      
    return { data, isLoading, error };
  };
