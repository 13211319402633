import {create} from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'
import { Authority, LoginStore } from "../types/interfaces";
/**
 * So that the app doesn't take you back to the login page after hitting
 * the browser refresh button, save the state to session storage.
 * 
 * https://docs.pmnd.rs/zustand/integrations/persisting-store-data
 * 
 * To see session state in the browser, in Firefox
 * 
 * hamburger menu on the rightside of the toolbar | More Tools | Web Developer Tools
 * 
 * then look at the bottom and choose "Storage" | Session Storage | hostname / IP address
 * 
 * 
 */
export const useLoginStore = create<LoginStore>()(
  persist(
    (set) => ({
      accessToken: null,
      isLogged: false,
      authority: null,
      setAccessToken: (accessToken: string) =>
        set((state) => ({ accessToken, isLogged: true })),
      logoutUser: () => set((state) => ({ accessToken: null, isLogged: false })),
      setAuthority: (authority: Authority) => set((state) => ({ authority })),
    }),
    {
      name: 'pumakuna-token', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },    
  ));
/*
export const useLoginStore = create<LoginStore>()((set) => ({
  accessToken: null,
  isLogged: null,
  authority: null,
  setAccessToken: (accessToken: string) =>
    set((state) => ({ accessToken, isLogged: true })),
  logoutUser: () => set((state) => ({ accessToken: null, isLogged: false })),
  setAuthority: (authority: Authority) => set((state) => ({ authority })),
}));
*/
/*
export const useLoginStore = create<LoginStore>(
  persist(
    (set, get) => ({
      bears: 0,
      addABear: () => set({ bears: get().bears + 1 }),
    }),
    {
      name: 'food-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
*/