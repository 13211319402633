import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { useToggle } from '@mantine/hooks';

import { CompoundVertex, TerminalNodesAndLabel, VerticesAndEdges } from '../types/interfaces';

import FloatingWrapper from '../layout/FloatingWrapper';
import CytoscapeGraph from '../components/graph/CytoscapeGraph';
import FooterFormAndControls from '../components/graph/FooterFormsControl';
import GraphControlsDrawer from '../components/graph/GraphControlsDrawer';
import { convertCompoundVertexToTerminalNodes } from '../components/graph/graphUtils';
import { useNotificationStore } from '../store/useNotificationStore';
import { useAppropriateHook } from '../util/hookUtils';


const GraphPage = () => {
  const { filename } = useParams<{ filename?: string }>();
  const [currentNodeId, setCurrentNodeId] = useState<string | null>('node-1');
  const [nodeName, setNodeName] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [currentGenerations, setCurrentGenerations] = useState<number>(1);
  const [currentNodeData, setCurrentNodeData] = useState<TerminalNodesAndLabel | null>(null);

  const [ancestors, setAncestors] = useToggle<boolean>([true, false] as const);
  const [shouldFit, setShouldFit] = useToggle<boolean>([true, false] as const);

  const [descendants, setDescendants] = useToggle<boolean>();
  const [clearViewport, setClearViewport] = useToggle<boolean>();
  const [isDrawerOpen, setDrawerOpen] = useToggle();
  const { infoNotification, errorNotification } = useNotificationStore();
  const location = useLocation();

  // Determine if the route is 'tableau-prep'
  const pathSegments = location.pathname.split('/');
  const isTableauPrep = pathSegments.includes('tableau-prep');

  // Use the utility function to get the appropriate hook
  const query = useAppropriateHook(
    isTableauPrep, 
    filename!, 
    currentNodeId, 
    nodeName, 
    keyword, 
    currentGenerations, 
    ancestors, 
    descendants, 
    clearViewport
  );

  // Checking the data type returned by the query hook
  const queryData = query.data as VerticesAndEdges | CompoundVertex[] | null | undefined;
  const safeQueryData: CompoundVertex[] | undefined = Array.isArray(queryData) ? queryData : undefined;

  // Extract loading and error states from the query
  const isLoading = query.isLoading;
  const error = query.error;

  // Run once on component mount, the point is to open the drawer when the page is rendered, overlaying the left sidebar
  useEffect(() => {
    setDrawerOpen(true);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  // Show a notification if the query fetched no results
//  console.log("query.isLoading, " + query.isLoading + " queryData, " + queryData +  " infoNotification " + infoNotification);
  useEffect(() => {
    if (!query.isLoading && Array.isArray(queryData) && queryData.length === 0) {
      infoNotification("Your search returned no results.", "Search Result");
    }
  }, [query.isLoading, queryData, infoNotification]);

  // Update node data when the current node ID changes and query returns data
  useEffect(() => {
    if (!query.isLoading && currentNodeId && Array.isArray(query.data)) {
      console.log("currentNodeId is not null. Will convert to terminalNodes ", currentNodeId);
      const terminalNodes = convertCompoundVertexToTerminalNodes(currentNodeId, query.data);
      setCurrentNodeData(terminalNodes);
    }
  }, [currentNodeId, query.isLoading, query.data]);

  // Show error notifications if any error occurs during query fetching
  useEffect(() => {
    if (error) {
      const errorMessage = typeof error === 'string' ? error : error.message || 'An unforeseen error occurred while fetching graph data.';
      errorNotification(errorMessage, "Graph Data Fetch Error");
    }
  }, [error, errorNotification]);

  // Callback to handle layout stop event
  const handleLayoutStop = useCallback(() => {
    setShouldFit(false);
  }, [setShouldFit]);

  // Handle form submission to update the search criteria and reset current node ID
  const handleSearchFormSubmit = (
    nodeName: string, 
    keyword: string, 
    generations: number, 
    options: { ancestors: boolean, descendants: boolean, clearViewport: boolean }
  ) => {
  
    setNodeName(nodeName);
    setKeyword(keyword);
    setCurrentGenerations(generations);
    setAncestors(options.ancestors);
    setDescendants(options.descendants);
    setClearViewport(options.clearViewport);
    setCurrentNodeId(null); // Clear selected node ID to prioritize the search query results
    setShouldFit(true); // Ensure the graph fits the viewport with new data
  };

  // Handle node click to fetch data based on node ID
  const handleNodeClickFetchData = (nodeId: string, displayType: 'ancestors' | 'descendants') => {
  
    setCurrentNodeId(nodeId);
    setDrawerOpen(true); // Open the drawer to display the node's data
    setNodeName(''); // Clear node name since we're now navigating via node IDs
    setKeyword('');
    setAncestors(displayType === 'ancestors');
    setDescendants(displayType === 'descendants');
  };

  // Reset the clearViewport state when viewport is cleared
  const handleViewportCleared = () => {
    setClearViewport(false);
  };

  return (
    <>
      <GraphControlsDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setCurrentNodeData(null); // Optional: Clear current node data on close
        }}
        nodeData={currentNodeData} // Pass in the current node data
      />

      <CytoscapeGraph
        data={safeQueryData} // Use the safe query data
        onNodeClickFetchData={handleNodeClickFetchData} // Handle node clicks to fetch data
        clearViewport={clearViewport} // Pass clearViewport state
        onClearComplete={handleViewportCleared} // Handle viewport clear complete event
        shouldFit={shouldFit} // Pass shouldFit state
        setShouldFit={setShouldFit} // Pass setShouldFit toggle function
        onLayoutStop={handleLayoutStop} // Pass handleLayoutStop callback
        isLoading={isLoading} // Pass the loading state
        error={error} // Pass the error state
      />

      <FloatingWrapper>
        <FooterFormAndControls filename={filename!} onSubmit={handleSearchFormSubmit} />
      </FloatingWrapper>
    </>
  );
};

export default GraphPage;
