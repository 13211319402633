import { Table, TableProps, Text } from "@mantine/core";
import { TerminalNode  } from "../../types/interfaces";
import styles from './TerminalsTable.module.css';

type TerminalsTableProps = {
  terminals: TerminalNode[]; //sAndLabel;
} & TableProps;

// Directly annotate the function parameters with the TerminalsTableProps type
const TerminalsTable = ({ terminals, ...tableProps }: TerminalsTableProps) => {
  // Filter out any nodes with the name 'thumbnail'
  const nonThumbnailNodes = terminals.filter(node => node.name !== 'thumbnail');

//  console.log(JSON.stringify(nonThumbnailNodes));

  
  // Render non-thumbnail nodes in a structured table, or null if there are none  

  return nonThumbnailNodes.length > 0 ? (
    
    <Table className={styles.table} {...tableProps}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {nonThumbnailNodes.map((node, index) => {
          return (
            <tr key={`node-${node.name}-${index}`}>
              <td>{node.name}</td>
              <td>{node.value}</td>
            </tr>
          );
        })}



      </tbody>
    </Table>
    ) : (
      <Text>No properties</Text>
    );
}
export default TerminalsTable;
      
/*
            <td className={styles.clickableCell}>{node.value}</td>
          </tr>
        ))}
        */

