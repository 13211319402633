import { Core } from "cytoscape";
import { MutableRefObject } from "react";

// Internal pan function, not exported because it's a private helper
const pan = (
    cyRef: MutableRefObject<Core | undefined>,
    dx: number,
    dy: number
  ) => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.panBy({ x: dx, y: dy });
    }
  };

// Exported handler functions that call the internal pan function
export const panLeftHandler = (cyRef: MutableRefObject<Core | undefined>) => pan(cyRef, -50, 0);
export const panRightHandler = (cyRef: MutableRefObject<Core | undefined>) => pan(cyRef, 50, 0);
export const panUpHandler = (cyRef: MutableRefObject<Core | undefined>) => pan(cyRef, 0, -50);
export const panDownHandler = (cyRef: MutableRefObject<Core | undefined>) => pan(cyRef, 0, 50);

export const zoomInHandler = (
        cyRef: MutableRefObject<Core | undefined>
    ) => {
        if (cyRef.current) {
            const cy = cyRef.current;    
            cy.zoom(cy.zoom() * 1.1);
            cy.center();
        }
};

export const zoomOutHandler = (
    cyRef: MutableRefObject<Core | undefined>
    ) => {
        if (cyRef.current) {
            const cy = cyRef.current;    
            cy.zoom(cy.zoom() * 0.9);
            cy.center();
        }
};

 export const fitGraphHandler = (
        cyRef: MutableRefObject<Core | undefined>
    ) => {
        cyRef.current?.fit();  
};