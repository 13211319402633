import React from "react";

import {
  Container,
  Grid,
  Group,
} from "@mantine/core";
import LoadingSpinner from "../components/display/LoadingSpinner";
import ProductCard from "../components/display/ProductCard";
import { useProductQuery } from "../hooks/useProductsQuery";
import { useLoginStore } from "../store/useLoginStore";
import ActiveCategory from "../components/products/ActiveCategory";
import AddProductButton from "../components/products/AddProductButton";

/**
 * 
 * This is the hacked up layout of the home page, below the nav bar.
 * It does not include the left sidebar that has the categories, and then in
 * the main pane, the category name that's selected, a button to 
 * "Add a product" (if the person is not a customer & has elevated 
 * priviledges) and then cards of all of the products.
 * 
 * All of this is laid out in Containers & Grids.
 * 
 */
const Home = () => {
  // Hooks
  const { authority } = useLoginStore();
  // ADT let's hack! See http.ts for fetch queries. And
  // workspace/react/udemy-course/new-24-tanstack-query
  // For where I got all these ideas.

  // Axios default header setting
  //  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  // Query for products data
  const {data: productsData, isLoading: isLoadingProducts, isFetching: isFetchingProducts} = useProductQuery();
  
  // See: Udemy's src/components/Events/NewEvent.jsx for useMutation example
  // mutation for deleting a product
  // <LoadingSpinner />
  return isLoadingProducts ? (
    <></>  
  ) : (
    <Container fluid p={0}>
      <Grid p={0} columns={11} mih={"92vh"} m={0}>
        <Grid.Col span={9}>
          <Group align="apart" mb={"md"} c={"blue"}  justify="flex-end">
          <ActiveCategory/>
            {authority !== "CUSTOMER" && (
              <AddProductButton/>
            )}
          </Group>
          <Grid columns={4} gutter={"lg"} mx={"xs"}>
            {
              // Display loading spinner if the data is being re-fetched
              isFetchingProducts ? (
                <Grid.Col span={4}>
                  <LoadingSpinner />
                </Grid.Col>
              ) : (
                productsData?.map((product) => {
                  return (
                    <Grid.Col key={product.id} span={1}>
                      <ProductCard key={product.id} product={product} />
                    </Grid.Col>
                  );
                })
              )
            }
          </Grid>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
 

export default Home;
