import {
  Text
} from "@mantine/core";


/**
 * For the right sidebar, for displaying cytoscape
 * nodes' JSON strings (turned into something pretty)
 * @param param0 
 * @returns 
 */

import { DataProps } from "../types/interfaces";
import { NodeContext } from "../App";
import { useContext } from "react";

const SidebarContent = ({ data }: DataProps) => {  
  let dataEntries;


  const { node } = useContext(NodeContext)!; // Again, use '!' to assert that the context will be defined  

  if (node?.data().value) {

    const data = JSON.parse(node?.data().value);
    dataEntries = Object.entries(data);
  }

  return (
      <div style={{ padding: '20px' }}>
          {dataEntries && (
              dataEntries!.map(([key, value]) => (
                <div key={key}>
                  <Text size="lg">{key}:{JSON.stringify(value).replace(/"/g,'')}</Text> 
                </div>
              )))
            }        
      </div>
    );
  };

  export default SidebarContent;