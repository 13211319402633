import cytoscape, { Collection, Core, EdgeSingular, LayoutOptions, NodeSingular } from "cytoscape";
import { CompoundVertex, CytoscapeNodesAndEdges, EdgeData, NodeData, TerminalNode, TerminalNodesAndLabel, VerticesAndEdges } from "../../types/interfaces";

/**
 * Cytoscape.js itself does not provide a built-in function to 
 * filter out elements with duplicate IDs when you attempt to add them
 * to the graph. The responsibility of ensuring unique IDs for elements
 * falls on you as the developer. However, you can write a helper function
 * to filter out duplicates based on IDs before adding new elements to the graph.
 */
export const getUniqueElements = (
  newData: CytoscapeNodesAndEdges,
  existingElements: Collection
): CytoscapeNodesAndEdges => {
  // Extract 'id' from existing nodes and edges into a Set for fast lookup
  const existingIds = new Set(
    existingElements.map(ele => ele.id())
  );

  // Filter new vertices and edges to include only those with unique IDs
  const uniqueNodes = newData.nodes.filter(
    node => !existingIds.has(node.data.id)        
  );
  
  const uniqueEdges = newData.edges.filter(
    edge => !existingIds.has(edge.data.id)
  );

  // Return the filtered object
  return {
    nodes: uniqueNodes,
    edges: uniqueEdges
  };
};    


// Function to add nodes to the Cytoscape graph
export const addNodesAndEdgesToGraph = (layoutOptions: LayoutOptions, newData: VerticesAndEdges, cyInstance: Core) => {
  const newElements = {
    nodes: newData.vertices.map((v) => ({ 
      data: { id: `node-${v.id}`, label: v.key, value: v.value, level: v.level},  
    })),      

    edges: newData.edges.map((e) => ({ 
      data: { id: `edge-${e.id}`, source: `node-${e.sourceId}`, target: `node-${e.targetId}`, label: e.type },                
    })),
  }; 
  const uniqNewElements = getUniqueElements(newElements, cyInstance.elements());
  console.log("Will load elements (unique)" + JSON.stringify(uniqNewElements));
  console.log("new nodes count = " + uniqNewElements.nodes.length);  
  console.log("new edges count = " + uniqNewElements.edges.length);

  const uniqNewElementsCount =   uniqNewElements.nodes.length + uniqNewElements.edges.length;
 /**
   * If you're dealing with a large dataset, consider the possibility 
   * of performance issues. Adding a lot of elements to the Cytoscape
   * instance or running a complex layout could be expensive. Use the
   * batch method provided by Cytoscape for bulk operations to minimize
   * redraws and improve performance.
   */   
  if (uniqNewElementsCount === 0) {
    console.log("No unique nodes or edges returning");
    return;
  }
  
  cyInstance.add(uniqNewElements);

  console.log("CY Elements");
  console.log(cyInstance.elements().jsons()); // Output all the current elements to console                      
  //cyInstance.layout(layoutOptions).run(); // Adjust the layout to your needs
  return;
}

export const convertCompoundVertexToTerminalNodes = (
  nodeId: string, 
  vertices: CompoundVertex[]
): TerminalNodesAndLabel => {

  console.log(`nodeId ${nodeId}`);
  console.log(`vertices count = ${vertices.length}`);
  //console.log("convertCompoundVertexToTerminalNodes", JSON.stringify(vertices));
  /**
   * Experimentations with "reduce"
   * In this refactored function, the reduce method iterates over each
   * CompoundVertex in vertices, keeping the collected terminals and the potentially
   * updated label in the accumulator acc. It uses JavaScript logic to set the label
   * once and compiles the terminal nodes.
   * 
   * The use of reduce provides a clean, single-pass solution over the array, maintaining
   * the functional paradigm and keeping mutation contained.
   */
  const result = vertices.reduce<{ terminals: TerminalNode[]; label: string }>(
    (acc, v) => {
      const matchId = `node-${v.id}`;

      const hasElements = v.childElements !== null;

      // Set the label if it's the node ID that the user clicked on.
      if (matchId === nodeId) {
        acc.label = v.key;        
      }

      if (hasElements && matchId == nodeId) {
        //const childElementsObj = JSON.parse(v.childElements);
        const childElementsObj: Record<string, string> = JSON.parse(v.childElements);

        const terminalNodes: TerminalNode[] = Object.entries(childElementsObj).map(([name, value]) => ({
          name,
          value
        }), []);        
/*
        const terminalNodes: TerminalNode[] = Object.entries(childElementsObj).reduce<TerminalNode[]>((acc, [key, nestedValue]) => {
          // Convert `nestedValue` to its correct type from `any`
          const nestedObj: {[key: string]: string} = nestedValue as {[key:string]: string};

          // Check if 'has-ref' exists and is true, then exclude it from the nested object
          const isClickable: boolean = !!nestedObj['has-ref'];

          // Remove 'has-ref' so it's not processed as a node                    
          delete nestedObj['has-ref']; 
        
          // Since there can be 1+ entries under each id, we handle them with an inner reduce
          const nodesForId = Object.entries(nestedObj).reduce<TerminalNode[]>((innerAcc, [name, value]) => {
            const terminalNode: TerminalNode = {
              id: parseInt(key, 10), // Convert `key` string to number for `id`
              name: name,
              value: String(value), // Ensure `value` is treated as string
              isClickable: isClickable // Set based on 'has-ref'              
            };
            innerAcc.push(terminalNode);
            return innerAcc;
          }, []);
        
          acc.push(...nodesForId);
        
          return acc;
        }, []);        
*/
        
        acc.terminals.push(...terminalNodes)      ;
      }

      return acc;
    },
    { terminals: [], label: '' }
  );

  return {
    id: nodeId,
    label: result.label,
    terminalNodes: result.terminals,
  };  
}


/**
 * This function can stand some refactoring.
 */ 
export const addCompoundNodesToGraph = (vertices: CompoundVertex[], cyInstance: cytoscape.Core): void => {
  const nodeIds = new Set<string>(); // To keep track of added nodes     
  
  const { nodes, edges } = vertices.reduce(
    (acc, v) => {
      const label    = v.key;
      const nodeId   = `node-${v.id}`;
      const parentId = v.parentId !== null ? `node-${v.parentId}` : null;

      //console.log("addCompoundNodesToGraph nodeId = " + nodeId);
      //console.log("addCompoundNodesToGraph parentId = " + parentId);

      nodeIds.add(nodeId); // Add node ID to the set
      //console.log("childELements " + JSON.stringify(v.childElements));
      const nodeData: NodeData = {
        id: nodeId,
        label, // Shorthand for 'label: label'
        value: v.value,
        childElements: v.childElements,
        level: v.level,
        //descendants: v.descendantCount,
        //parent: v.descendantCount === 0 && parentId ? parentId : undefined,
      };
      //console.log("node = " + JSON.stringify(nodeData));
      acc.nodes.push({ data: nodeData });

      // Only push the edge if it has a parent & we've seen that parent
      // Or, as a fallback, if the array is out of order (because we're relying on an "order by"
      // in the database), look to see if the id appears in the array of CompoundVertex
      if (parentId && (nodeIds.has(parentId) || vertices.find(entry => entry.id === v.parentId))) {
        const edgeData: EdgeData = {
          id: `edge-${v.parentId}-${v.id}`,
          source: parentId,
          target: nodeId,
          type: v.type
        };
        //console.log("edge = " + JSON.stringify(edgeData));
        acc.edges.push({ data: edgeData });
      }
  //console.log("acc nodes = " + JSON.stringify(acc.nodes));
//  console.log("acc edges = " + JSON.stringify(acc.edges));
      return acc;
    },
    { nodes: [] as { data: NodeData }[], edges: [] as { data: EdgeData }[] }    
  );
   
  
  const elements: cytoscape.ElementDefinition[] = [...nodes, ...edges];  

  cyInstance.add(elements);

  return;
}

