import { useLoginStore } from "../store/useLoginStore";

/**
 * 
 * The getToken function aims to provide an abstraction for
 * retrieving the authorization token when making an HTTP request.
 * It should be implemented in such a way that it returns the
 * current user's token as a string. When working with Zustand,
 * a global state management library, you would retrieve the token
 * from the store.
 * 
 * 
 * By using useStore.getState(), you are directly accessing the Zustand 
 * store state outside of React's component lifecycle, which means it's not
 * subject to the rules of hooks. This allows you to call getToken within
 * any utility function or even in places where hooks are generally not allowed,
 * such as class components or non-component modules.
 * 
 * This technique is particularly handy when you need to access your store's
 * state in contexts where hooks aren't feasible or when the state must be
 * retrieved synchronously, such as in a utility module.
 * 
 * 
 */
export const getAccessToken = (): string | null => {
  // Directly access the Zustand store's state to retrieve the token
  return useLoginStore.getState().accessToken;
};