import React, { useState, FormEvent } from 'react';
import { NumberInput, Group, ActionIcon, Title, Box, Checkbox, Alert, Tooltip } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { useToggle } from '@mantine/hooks';
import NodeNamePicker from '../graph/NodeNamePicker';
import KeywordTextbox from '../graph/KeywordTextbox';

interface ValidationState {
    hasError: boolean;
    message: string;
}

const SearchGraphNodeForm = ({
    filename,
    onSubmit // Pass just the onSubmit handler
    
} : {
    filename:string;
    onSubmit: (nodeName: string, keytword: string, generations: number,
        options: { ancestors: boolean, descendants: boolean, clearViewport: boolean }) => void;
}) => {

    // Keep local state, that is only passed to the parent when
    // the handleSubmit handler gets called. 
    const [localNodeName, setLocalNodeName] = useState<string>('');
    const [localKeyword, setLocalKeyword] = useState<string>('');

    const [localGenerations, setLocalGenerations] = useState<number>(1);

    
    // I think this is the idiom for defaulting to true with Mantine's useToggle hook
    // https://mantine.dev/hooks/use-toggle/    
    const [localAncestors, setLocalAncestors] = useToggle<boolean>([true, false] as const);
    
    // The useToggle hook defaults to false
    const [localDescendants, setLocalDescendants] = useToggle<boolean>();
    const [localClearViewport, setLocalClearViewport] = useToggle<boolean>();    
  
    const [validationState, setValidationState] = useState<ValidationState>({ hasError: false, message: '' });    
        
    // handleSubmit calls onSubmit prop with local state
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Check that at least one of the checkboxes is checked
        /*
        if (!localAncestors && !localDescendants) {
            // If neither checkbox is checked, set validation error and stop form submission
            setValidationState({
                hasError: true,
                message: "When generations > 0, please check at least one of the 'Ancestors' or 'Descendants' options."
            });            
            return;
        }
    */
        // Validation for localNodeName and localKeyword to ensure not both are empty or undefined
        if (!localNodeName.trim() && !localKeyword.trim()) {
            // Provide user feedback that at least one must be entered
            setValidationState({
                hasError: true,
                message: "Please enter a node name and/or a keyword.",
            });            
            return; // Stop form submission as validation failed
        }        

        // If validation passes, clear the error state and proceed with form submission
        setValidationState({ hasError: false, message: '' });

        onSubmit(localNodeName, 
            localKeyword,
            localGenerations, 
            { ancestors: localAncestors,
                descendants: localDescendants,
                clearViewport: localClearViewport  });
        // Reset checkboxes if necessary
        // setLocalAncestors(false);
        // setLocalDescendants(false);
        setLocalClearViewport(false);

        console.log("SearchGraphNodeForm.handleSubmit");
    };    

    return (
        <div>
         {/* Place the Alert outside of the Box that encapsulates the form contents */}
            {validationState.hasError && (
                <Box style={{ position: 'relative', zIndex: 9999, pointerEvents: 'none' }}> {/* Box for alert overlay */}
                <Alert
                    title="Validation error"
                    color="red"
                    withCloseButton
                    onClose={() => setValidationState({ hasError: false, message: '' })}                    
                    style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto', // centers the Alert in the Box
                    pointerEvents: 'all',
                    width: 'fit-content',
                    maxWidth: '100%', // ensures the Alert does not exceed the Box width
                    backgroundColor: 'white',
                    // Add these styles to ensure Alert doesn't extend outside of Box
                    boxSizing: 'border-box',
                    padding: '6px 12px',
                    fontSize: '12px',
                    lineHeight: '1.4',
                    // ... add any other styles you need here
                    }}>
                    {validationState.message}                        
                </Alert>
                </Box>
            )}
        
            <form onSubmit={handleSubmit}>  
                <Box style={{ paddingLeft: '10px' }}> {/* Adjust padding value as needed */}              
                    <Title order={4} style={{ fontWeight: 'bolder', fontSize: '14px',margin: '0 0 10px' }}>
                        Search nodes by name, generation, and direction</Title>        
                </Box>
                <Group  style={{ flexWrap: 'nowrap', alignItems: 'center'}}> {/* align items properly in the flex container */}        
                    <NodeNamePicker 
                        filename={filename} 
                        nodeName={localNodeName} 
                        setNodeName={setLocalNodeName}
                    />
                    <KeywordTextbox
                        keyword={localKeyword}
                        setKeyword={setLocalKeyword}
                        />
                    <Tooltip label="Number of generations to include. Please note: the larger the number, the longer it will take to render" position="bottom" withArrow={true}>                                                                                            
                        <NumberInput
                            label="Generations"
                            value={localGenerations}
                            onChange={(value) => setLocalGenerations(Number(value))}
                            placeholder="Number of generations"
                            min={1}
                            required
                            style={{ width: '100px' }} // You may need to adjust width to fit your design            
                        />
                    </Tooltip>                        
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'fit-content' }}>

                        <Tooltip label="Search for parent, grandparent, ... nodes" position="bottom" withArrow={true}>                        
                            <Checkbox label="Ancestors" checked={localAncestors} onChange={(event) => setLocalAncestors(event.currentTarget.checked)} />
                        </Tooltip>                            
                        <Tooltip label="Search for children, granchildren, ... nodes" position="bottom" withArrow={true}>                                                
                            <Checkbox label="Descendants" checked={localDescendants} onChange={(event) => setLocalDescendants(event.currentTarget.checked)} />
                        </Tooltip>
                        <Tooltip label="Clear the screen of existing nodes" position="bottom" withArrow={true}>                                                                        
                            <Checkbox label="Clear Nodes" checked={localClearViewport} onChange={(event) => setLocalClearViewport(event.currentTarget.checked)} />
                        </Tooltip>
                    </Box>      
                    <Tooltip label="Submit search" position="bottom" withArrow={true}>
                        <ActionIcon type="submit" variant="gradient" color="blue" size={36} style={{ alignSelf: 'center' }}>
                            <IconSearch size={18} />
                        </ActionIcon>        
                    </Tooltip>    
                </Group>
            </form>
        </div>
    );
};


export default SearchGraphNodeForm;
