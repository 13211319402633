import { Button, Center, Flex, Group, Image, Title } from "@mantine/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CatLogo } from "../assets/cats.svg";
import { useLoginStore } from "../store/useLoginStore";

const Landing = () => {
  const navigate = useNavigate();
  const { isLogged } = useLoginStore();

  console.log("Landing isLogged = " + isLogged);
  useEffect(() => {
    if (isLogged) {
      navigate("/app");
    }
  }, [isLogged]);

  // replace fontweight bold with fw={700}
  // https://mantine.dev/core/text/
  return (
    <Center h={"100vh"}>
      <Flex direction={"column"} align="center" gap={"md"}>
        <Flex justify={"center"} align="center">
          <Title pb="xl" c="blue" fw={400} size={44} mt={40} style={{
	          textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
	        }}>
            Pumakuna
          </Title>
        </Flex>
        <CatLogo height={550} />
        <Group align="center">
          <Button
            type="button"
            mt="xl"
            onClick={() => {
              navigate("/login");
            }}
          >
            Click here to login/register
          </Button>
        </Group>
      </Flex>
    </Center>
  );
};

export default Landing;
