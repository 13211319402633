// src/utils/hooksUtil.ts
import { useTableauPrepQueryWithFilename } from '../hooks/useTableauPrepQuery'; // Assuming you have this hook defined
import { useGraphQueryCompoundNodesByNameWithFilename, useGraphQueryCompoundNodesByIdWithFilename } from '../hooks/useGraphQuery';


export const useAppropriateHook = (
  isTableauPrep: boolean, 
  filename: string, 
  currentNodeId: string | null, 
  nodeName: string, 
  keyword: string, 
  currentGenerations: number, 
  ancestors: boolean, 
  descendants: boolean, 
  clearViewport: boolean
) => {

  // Case 1: isTableauPrep
  if (isTableauPrep) {
//    console.log("returning useTableauPrepQueryWithFilename");
    return useTableauPrepQueryWithFilename(filename!);
  }

  // Case 2: nodeName or keyword
  if (nodeName || keyword) {
//    console.log("returning useGraphQueryCompoundNodesByNameWithFilename");
    return useGraphQueryCompoundNodesByNameWithFilename(filename!, nodeName, keyword, currentGenerations, ancestors, descendants, clearViewport);
  }

  // Case 3: currentNodeId
  if (currentNodeId) {
//    console.log("returning useGraphQueryCompoundNodesByIdWithFilename");
    return useGraphQueryCompoundNodesByIdWithFilename(filename!, currentNodeId, currentGenerations, ancestors, descendants, clearViewport);
  }

  // Default case where none of the conditions are met
  return { data: null, isLoading: false, error: null };
};

