import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import mermaid, { RenderResult } from 'mermaid';
import svgPanZoom from 'svg-pan-zoom';
import { AppShell, Container, Box, Title } from '@mantine/core';
import { useTableauPrepMermaidQueryWithFilename } from '../hooks/useTableauPrepMermaidQuery'; 
import { useNotificationStore } from '../store/useNotificationStore';

// Set up Mermaid: disable automatic rendering since we'll handle it manually
mermaid.initialize({
  startOnLoad: false,
  theme: 'default', // Ensure theme is set correctly
  maxTextSize: 4000000, // Setting maxTextSize to 4 Megabytes
  // Company profiles has 740+ edges. Exceeds the default of 500
  maxEdges: 1000
});


const MermaidPage = () => {
  const { filename } = useParams<{ filename?: string }>();
  const { errorNotification } = useNotificationStore();

  if (!filename) {
    errorNotification('No filename provided', 'Error');
    return null; // Render nothing since we're showing a notification
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const panZoomInstanceRef = useRef<ReturnType<typeof svgPanZoom> | null>(null); 

  const diagramQuery = useTableauPrepMermaidQueryWithFilename(filename);
  const { data: diagramDefinition, isLoading, error } = diagramQuery;

  useEffect(() => {
    if (isLoading) {
      console.log('Loading diagram...');
      return;
    }

    if (error) {
      console.error('Error fetching diagram:', error);
      errorNotification('Failed to fetch the Mermaid diagram', 'Error');
      return;
    }

    const renderMermaid = async () => {
      if (containerRef.current && diagramDefinition) {
        try {
          const result: RenderResult = await mermaid.render('mermaid-diagram', diagramDefinition);
          containerRef.current.innerHTML = result.svg;

          if (panZoomInstanceRef.current) {
            panZoomInstanceRef.current.destroy();
          }
          const svgElement = containerRef.current.querySelector('svg');
          if (svgElement) {
            svgElement.style.width = '100%';
            svgElement.style.height = '100%';
            svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet'); // Make sure the SVG preserves its aspect ratio

            // Ensure the viewBox attribute is set
            if (!svgElement.getAttribute('viewBox')) {
              const width = svgElement.getAttribute('width');
              const height = svgElement.getAttribute('height');
              if (width && height) {
                svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
              }
            }

            panZoomInstanceRef.current = svgPanZoom(svgElement, {
              controlIconsEnabled: true,
              zoomEnabled: true,
              panEnabled: true,
              fit: true,
              center: true,
              minZoom: 0.5,
	      // For huge graphs like Company_Profile_flow, this number needs to be large.
              maxZoom: 100,
              zoomScaleSensitivity: 0.2,
              dblClickZoomEnabled: true
            });

            // Ensure the diagram is fit to the container
            panZoomInstanceRef.current.resize();
            panZoomInstanceRef.current.fit();
            panZoomInstanceRef.current.center();
          }
        } catch (error) {
          console.error('Failed to render Mermaid diagram:', error);
          errorNotification('Failed to render the Mermaid diagram', 'Error');
        }
      }
    };

    renderMermaid();
  }, [diagramDefinition, isLoading, error, errorNotification]);

  return (
    <AppShell>
      <Container fluid style={{ padding: 0 }}>
        <Title order={2} style={{ textAlign: 'center', margin: '20px 0' }}>
          {filename} flowchart
        </Title>
        <Box ref={containerRef} className="mermaid" style={{
          width: '100%',
          height: '80vh',
          overflow: 'hidden',
        }} />
      </Container>
    </AppShell>
  );
};

export default MermaidPage;
