import React, { useState } from 'react';
import { Modal, Text } from '@mantine/core';
import Draggable from 'react-draggable';

interface DraggableModalProps {
    children: React.ReactNode;
    title: string;
    opened: boolean;
    setOpened: (opened: boolean) => void;
  }
  
const DraggableModal1 = ({ children, title, opened, setOpened }: DraggableModalProps) => {
  return (
    <Draggable handle=".modal-title-bar">
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        opacity={0.55}
//        overlayBlur={3}
        centered
        size="lg"
        styles={{
//          modal: {
//            position: 'absolute', // Override default position
//          }
        }}
      >
        <div className="modal-title-bar" style={{ cursor: 'move', marginBottom: 20 }}>
          <Text fw={500}>{title}</Text>
        </div>
        {children}
      </Modal>
    </Draggable>
  );
};

const MyComponent = () => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <button onClick={() => setOpened(true)}>Open Draggable Modal</button>
      <DraggableModal1
        title="Draggable Modal"
        opened={opened}
        setOpened={setOpened}
      >
        {/* Contents of the modal */}
        <Text>This is a draggable modal. You can drag it by holding down the title bar.</Text>
      </DraggableModal1>
    </>
  );
};

export default MyComponent;