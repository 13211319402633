import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { Textarea,
  Button,
  FileButton,
  Checkbox,
  Container,
  Paper,
  Title,
  Text,
  Flex,
  List,
  ThemeIcon, 
  rem, 
  Tooltip  } from '@mantine/core';

import { modals } from "@mantine/modals";
import { useFileUpload } from '../../hooks/useFileUpload';
import { FileUploadBody } from '../../types/interfaces';
import { IconCircleCheck, IconFile, IconUpload } from '@tabler/icons';
import LoadingSpinner from '../display/LoadingSpinner';
/*
 * An alternate experimentation, using Mantine's FileButton, rather than the 
 * FileInput. It means that I'll have to capture the state myself, rather than
 * relying on the form's input values.
 */

const UploadFileInputForm = () => {
 
    const form = useForm({
        initialValues: {
            files: null,
            notes: '',
            overwrite: false,
        },
     });  

    const [files, setFiles] = useState<File[]|null>(null);
    const resetRef = useRef<() => void>(null);
    const [notes, setNotes] = useState("");
    const [overwrite, setOverwrite] = useState(false);    
    
    
    // The reset function is per: https://github.com/TanStack/query/discussions/1014
    const {uploadFile, reset, isSuccess, isPending, isError } = useFileUpload();
    
    const clearFile = () => {
      setFiles(null);
      setOverwrite(false);
      resetRef.current?.();
    };    

    if (isPending) {
      return <LoadingSpinner />;
    }    

    
    if (isSuccess) {
      // Using useMutation's reset function to reset the booleans to their initial state.
      // Specifically isSuccess. Because without resetting things, this form will never
      // again show, because isSuccess would be true, and we'd forever return here.
      reset();
      return <></>;
    }

    const openModal = () => modals.openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size="sm">
          This action is so important that you are required to confirm it with a modal. Please click
          one of these buttons to proceed.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => {
        console.log('Cancel upload');
      },
      onConfirm: () => {
        console.log('Confirmed');
        uploadFile({ 'files': files,'notes' :notes, 'overwrite' : overwrite});
        clearFile();
      },
    });    
   
    return (
      <Container>
        <Paper withBorder shadow={"xl"} p={"xl"} m={"xl"}>
          <Title order={2} mb={"md"} c="blue">
            Upload Tableau or Cognos files
          </Title>

          <form
            onSubmit={form.onSubmit(() => {
              if (overwrite) {
                openModal();
              } else {
                uploadFile({ 'files': files,'notes' :notes, 'overwrite' : overwrite});
                clearFile();
              }})
            }
           >

            <FileButton onChange={setFiles} multiple>
              {(props) => <Button variant="outline" {...props}>Select files</Button>}
            </FileButton>

            <Textarea 
                mt="xl"
                c="blue"
                label="Notes"
                placeholder="Max 10 lines of notes"
                autosize
                minRows={4}
                maxRows={10}
                onChange={(event) => setNotes(event.currentTarget.value)}                
            />

            <Tooltip label="Check the box if the file has already been processed previously, but you would like to replace it with this version."
              refProp="rootRef">
              <Checkbox
                  c="blue"
                  mt="xl"
                  checked={overwrite}
                  onChange={(event) => setOverwrite(event.currentTarget.checked)}                
                  label="Over-write previous version of the file, if it already exits?"
              />
            </Tooltip>

            {files && files.length > 0 && (
              <Text size="md" fw={500} mt="lg" c="blue">
                Selected files:
              </Text>
            )}

            <List
              withPadding
              icon={
                <ThemeIcon color="gray" size={24} radius="xl">
                  <IconFile style={{ width: rem(16), height: rem(16) }} />
                </ThemeIcon>
              }
              >
              {files?.map((file, index) => (
                <List.Item key={index}>{file.name}</List.Item>
              ))}
            </List>

            <Flex
              mih={50}
              gap="md"
              justify="flex-end"
              align="flex-end"
              direction="row"
              wrap="wrap"
              >            
              <Button mt="xl" variant="outline" disabled={files === null || files.length === 0} color="red" onClick={clearFile}>
                Reset
              </Button>


              <Button 
                  type="submit" mt="xl" variant="outline" disabled={files === null || files.length === 0}>              
                  Upload now                    
                  <IconUpload/>
              </Button>
            </Flex>          
          </form>
        </Paper>
      </Container>

    )

}

export default UploadFileInputForm;