import { useQueryClient, useMutation } from "@tanstack/react-query";
import { deleteProductById } from "../util/http";
import { useNotificationStore } from "../store/useNotificationStore";
import { useLoginStore } from "../store/useLoginStore";

export const useProductDelete = () => {
  // Access the react query singleton client
  const queryClient = useQueryClient();
  const notificationStore = useNotificationStore();
  const { accessToken } = useLoginStore();

  // Alias the reference of the mutate function pointer to "productDelete"
  const { mutate: productDelete, isPending } = useMutation({


    mutationFn: (productId: number) => 
    // The next fires the "useMutation", which is in the useProductDelete hook,
    // passing in the arguments it expects.
    // Type 'string | null' is not assignable to type 'string'.
    // Type 'null' is not assignable to type 'string'.ts(2322).
    // There's probably a cleaner way of doing this.
    // So I need to test if it's null and I figure return a blank
    // string if it is:    
    deleteProductById({productId: productId, accessToken: accessToken || "" }),

    onSuccess: () => {
      // call query client to force a refresh of the query, so we'll get an
      // immediate refresh
      //      console.log("Success in deleting " + productId);
      notificationStore.successNotification(
        "Successfully deleted item from the inventory."
      );
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
    onError: (data: Error) => {
      notificationStore.errorNotification(
        data.message,
        "Failed to delete product."
      );
    },
  });
  return { productDelete, isPending };
};


/*
const deleteCustomerMutation = useMutation({
    mutationFn: (customerId: number) => {
      return axios.delete<Customer[]>(
        `${import.meta.env.VITE_APP_BACKEND_URL}/customers/${customerId}`
      );
    },
    onSuccess: (data) => {
      notificationStore.successNotification("Deleted customer successfully");
      queryClient.setQueryData(["customers"], data);
    },

    onError: (data: AxiosError) => {
      notificationStore.errorNotification(
        data.message,
        "Cannot delete customer"
      );
    },
  });
  */