import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useMemo } from "react";
import { Link, To } from "react-router-dom";
import { MRT_ColumnDef, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { ActionIcon, Anchor,Badge, Box, Tooltip,Text } from "@mantine/core";
import { IconRefresh, IconChartDots3, IconHierarchy, IconFileText } from "@tabler/icons";

import { format } from "date-fns"; // Import date-fns

import { useFilesQuery } from "../../hooks/useFilesQuery";
import { ProcessedFile } from "../../types/interfaces";

const FileTable = () => {
    const {data, isError, isLoading, isFetching, refetch} = useFilesQuery();
    const fetchedFiles = data ?? [];
    const totalRows = fetchedFiles.length;

    /**
     * Values are:
     * 
     * filename: string;
     * userEmail: string;
     * fileTime: number;
     * filesize: number;
     * type: string;
     * notes: string;
     * insertTime: number;
     * edgeAndVertexCount: number;
     * startTime: number;
     * elapsedTime: number;
     * isProcessed: boolean;
     * updateCount: number;
     */
    const columns = useMemo<MRT_ColumnDef<ProcessedFile>[]>(
        () => [
          {
            accessorKey: 'filename',
            header: 'Name',
            Cell: ({ cell }) => <Anchor component={Link} to={`/app/file/${cell.getValue()}`}>
              <Text>{cell.getValue<string>()}</Text> 
            </Anchor>
          },     
          {
            accessorKey: 'fileTime', // Notice dot notation to get the child of category
            header: 'Timestamp',
 	    Cell: ({ cell }) => {
              const date = new Date(cell.getValue<string>());
              return <Text>{format(date, "yyyy-MM-dd HH:mm:ss")}</Text>;
            },	    
          },
     	  {
            accessorKey: 'type',
	    header: 'Type',
            // Simply output the type without a hyperlink
            Cell: ({ cell }) => <Text>{cell.getValue<string>()}</Text>,
      	  },
      	  {
            accessorKey: 'graph',
            header: 'Hierarchy',
            size: 30, // Specify the width of the column
            enableSorting: false, // Disable sorting
      	    Cell: ({ row }) => {
              const isProcessed = row.original.isProcessed;
              const type = row.original.type;

              return isProcessed && type === "tableau_prep" ? (
                <Tooltip label="View Graph">
              	  <ActionIcon component={Link} to={`/app/tableau-prep/${row.original.filename}`}>
                    <IconHierarchy /> {/* Icon representing graph with parents & children */}
              	  </ActionIcon>
            	</Tooltip>
              ) : null;
            },
          },
          {
  	    accessorKey: 'diagram',
            header: 'Flowchart',
            size: 30, // Specify the width of the column
            enableSorting: false, // Disable sorting	    
            // Conditionally render the icon and URL only if isProcessed is true
            Cell: ({ row }) => {
              const isProcessed = row.original.isProcessed;
              const type = row.original.type;
	      
              return isProcessed && type === "tableau_prep" ? (
                <Tooltip label="View Flowchart">
                  <ActionIcon component={Link} to={`/app/tableau-prep/diagram/${row.original.filename}`}>
                    <IconChartDots3 />		    
                  </ActionIcon>
                </Tooltip>
              ) : null;
            },
          },
          {
  	    accessorKey: 'flow',
            header: 'Tableau Prep Synopsis',
            size: 30, // Specify the width of the column
            enableSorting: false, // Disable sorting	    
            // Conditionally render the icon and URL only if isProcessed is true
            Cell: ({ row }) => {
              const isProcessed = row.original.isProcessed;
              const type = row.original.type;
	      
              return isProcessed && type === "tableau_prep" ? (
                <Tooltip label="View the Tableau Prep Flow as a document">
                  <ActionIcon component={Link} to={`/app/tableau-prep/flow/${row.original.filename}`}>
                    <IconFileText />		    
                  </ActionIcon>
                </Tooltip>
              ) : null;
            },
          },	  
    	  {
            accessorKey: "edgeAndVertexCount",
            header: "Edge and vertex count",
            enableSorting: false, // Disable sorting
            Cell: ({ row }) => {
              const edgeAndVertexCount = row.original.edgeAndVertexCount;
              const type = row.original.type;

              return type === "tableau_prep" && edgeAndVertexCount === 0 ? (
                <Text>-</Text>
              ) : (
                <Text>{edgeAndVertexCount}</Text>
              );
            },
      	  },
          {
            accessorKey: 'isProcessed',
            header: 'Status',
            Cell: ({ cell }) => 
                cell.getValue<boolean>() ? (
                    <Badge color="green" variant="light">
                      Processed
                    </Badge>
                  ) : (
                    <Badge color="yellow" variant="light">
                      In progress
                    </Badge>
                  ),
            size: 50,
          },      
        ],
        [],
      );    
      const table = useMantineReactTable({
        columns,
        data : fetchedFiles,
        rowCount: totalRows,
        initialState:{ pagination: { pageSize: 50, pageIndex: 0 } },    
        // The next two mask the weird blue up/down arrows that
        // I guess I introduce by having the font blue? I'm
        // not sure how to just make the blue boxes around
        // up/down arrows to be transparent or white?
        mantineTopToolbarProps: {bg: 'blue', c:'green'},
        mantineTableHeadCellProps: { c:'white', bg: 'blue'},    
        enableToolbarInternalActions: false,  
        enableColumnResizing: true,
        layoutMode: 'grid',    
        mantineToolbarAlertBannerProps: isError ? {
            color: 'red',
            children: 'Error loading data',
        } : undefined,    
        renderTopToolbarCustomActions: () => (
            <Tooltip label="Refresh Data">
              <ActionIcon onClick={() => refetch()}>
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
        ),
        renderDetailPanel: ({ row }) => (
            <Box
              style={{
                display: 'grid',
                margin: 'auto',
                // ADT the effect is it seems to indents 5% on both the left & the right
                width: '90%',
              }}
            >
                <Text>{row.original.notes}</Text>
            </Box>
        ),
        state: {
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isFetching,
          },
      });
      return <MantineReactTable table={table} />;  

}

export default FileTable;


