import { TextInput, Tooltip } from "@mantine/core";

const KeywordTextbox = ({
    keyword,
    setKeyword
} : {
    keyword: string;
    setKeyword: (keyword: string) => void;    
}) => {

    return (
        <Tooltip label="Keyword for further refinement" position="bottom" withArrow={true}>
            <TextInput
                label="Keyword"
                placeholder="Enter keyword"
                maxLength={50}
                value={keyword}
                onChange={(event) => setKeyword(event.currentTarget.value)}
                style={{ width: 200 }} // Adjust width to match your design
            />
        </Tooltip>   
    );
}
export default KeywordTextbox;