import { Text,Tooltip, UnstyledButton, useMantineTheme  } from "@mantine/core";

import { CSSProperties, ReactElement } from "react";

const ControlButton = ({ icon, label, onClick }: {
    icon: ReactElement;
    label: string;
    onClick: () => void;
  }) => {

const theme = useMantineTheme();

// Define button styles using themes defined values.
const buttonStyles : CSSProperties= {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  
};

// Define text styles using themes defined values.
const textStyles : CSSProperties = {
  //marginTop: compactSpacing, 
  lineHeight: 1.2,
  fontSize: theme.fontSizes.xs,
};

return (
  <Tooltip label={label} position="bottom" withArrow={true}>
    <UnstyledButton 
      style={buttonStyles} 
      onClick={onClick}
      >
      {icon}
      <Text style={textStyles}>{label}</Text>
    </UnstyledButton>
  </Tooltip>
);
};

export default ControlButton;


