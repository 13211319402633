import { useState } from 'react';
import { Anchor} from '@mantine/core';
import {
  IconKey,
  IconSettings,
  Icon2fa,
  IconLogout,
} from '@tabler/icons-react';
import classes from './NavbarSimple.module.css';
import { IconBuildingStore, IconDashboard, IconFiles, IconShoppingCart, IconUpload, TablerIcon } from '@tabler/icons';
import { useNavbarsStore } from '../store/useNavbarsStore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLoginStore } from '../store/useLoginStore';
import { useActiveCategoryStore } from '../store/useActiveCategoryStore';

export function NavbarSimple() {
  const navigate = useNavigate();
  const [active, setActive] = useState('Products');
  const { navbarElements } = useNavbarsStore(); 
  const {logoutUser  } = useLoginStore();
  const { clear: categoriesClear} = useActiveCategoryStore();
  const {pathname} = useLocation();


  const iconMap = new Map<string,JSX.Element>([
    ['products', <IconBuildingStore className={classes.linkIcon} stroke={1.5}/> ],
    ['upload', <IconUpload className={classes.linkIcon} stroke={1.5}/> ],
    ['dashboard', <IconDashboard  className={classes.linkIcon} stroke={1.5}/> ],
    ['keys', <IconKey className={classes.linkIcon} stroke={1.5}/> ],
    ['uploaded', <IconFiles className={classes.linkIcon} stroke={1.5}/> ],
    ['authentication', <Icon2fa className={classes.linkIcon} stroke={1.5}/> ],
    ['settings', <IconSettings className={classes.linkIcon} stroke={1.5}/> ],
    ['cart', <IconShoppingCart className={classes.linkIcon} stroke={1.5}/> ]          
  ]);

  // Changing this around to check the pathna returned by useLocation() and
  // doing a string match against the link. If they're the same, the link is 
  // active, and thus bolded/highlighted/shown as select.

  // Notice the exclamation point after the iconMap.get. It's in response to the error
  // message from typescript: Type 'undefined' is not assignable to type 'string | TrustedHTML'.ts(2322)
  // {iconMap.get(ele.link)!}

  const links = navbarElements?.sidebar.map((ele,idx) => (
    <Anchor
      className={classes.link}
      data-active={`/app/${ele.link}` === pathname || undefined}
      component={Link}
      key={idx}
      to={`/app/${ele.link}`}
      tt="capitalize"
    > 
      {iconMap.get(ele.link)!}
      <span>{ele.label}</span>
    </Anchor>

  ));

 
  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        {links}
      </div>

      <div className={classes.footer}>
        <Anchor
            className={classes.link}
            component={Link}
            to='#'
            onClick={(event) => {
              event.preventDefault();
              logoutUser();
              categoriesClear();            
              navigate("/login");
            }}
          > 
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>    
        </Anchor>
      </div>
    </nav>
  );
}