import { ReactNode } from 'react';
import { Affix, Box } from '@mantine/core';

type FloatingWrapperProps = {
  children: ReactNode;
};

/**
 * Wrapper to float child html/etc. at the bottom of the screen.
 * Includes the passing of onSubmit handler 
 * Right now it's hard coded. The Affix component makes the footer float.
 * @param param0 }
 * @returns 
 */
//    <Box style={{ height: 'var(--app-shell-footer-height)', width: '100hh',  backgroundColor: 'green'}}>    
// </Box>        
const FloatingWrapper = ({ 
    children
  }: { 
    children: ReactNode
  }) => (
  <Affix position={{ bottom: 0, right: 0, left: 'var(--app-shell-navbar-width)' }}> 

      {children}

  </Affix> 
);

export default FloatingWrapper;