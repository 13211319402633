import { useQuery } from "@tanstack/react-query";
import { fetchGraphByTypeAndFilename as fetchGraphByTypeAndFilename, fetchGraphByRootIdAndFilename, fetchGraphCompoundNodesByFilenameAndNodeId, fetchGraphCompoundNodesByFilenameAndNodeName } from "../util/http";
import { CompoundVertex, Edge, Vertex, VerticesAndEdges } from "../types/interfaces";
import { GRAPH_TYPE_GENERAL, GRAPH_TYPE_TABLEAU_PREP  } from "../types/constants";

export const useGraphQueryWithFilename  = (filename: string) => {
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["filename-graph", filename],
  
      queryFn: ({ signal }) =>
        fetchGraphByTypeAndFilename<VerticesAndEdges> ({signal, 
          graphType: GRAPH_TYPE_GENERAL,
          filename, 
          suppressUi: true }),        
    });
  
    return { data, isLoading, error };
};


/**
 * What root ID are we going to start with, and how many generations deep are we going to return ?
 */
export const useGraphQueryByRootIdWithFilename = (filename: string, node: string, generations: number) => {
    // Strip out the word "node" + the hyphen to get only the node ID
    const nodeId = node.substring(node.indexOf('-') + 1);

    const { data, isLoading, error } = useQuery({
      queryKey: ["filename-and-vertex-id", filename, nodeId], //, generations],
  
      //fetchGraphByRootIdAndFilename<VerticesAndEdges>({ signal, 
      
      queryFn: ({ signal }) =>
        fetchGraphByRootIdAndFilename<VerticesAndEdges>({  signal,
          filename, 
          node: nodeId,
          generations}),
    });
      
  
    return { data, isLoading, error };
};

export const useGraphQueryCompoundNodesByIdWithFilename  = (filename: string, node: string, generations: number,
  ancestors: boolean, descendants: boolean, clearViewport: boolean) => {

    console.log("useGraphQueryCompoundNodesByIdWithFilename: ", filename, " node: ",  node, " ancestors: ", ancestors, " descendants: ", descendants);
  // Check at the beginning of the hook to return early if node is null
  if (!node) {
    return { data: undefined, isLoading: false, error: null };
  }  

  // Strip out the word "node" + the hyphen to get only the node ID
  const nodeId = node.substring(node.indexOf('-') + 1);

  const { data, isLoading, error } = useQuery({
    queryKey: ["filename-and-compound-vertex-id", filename, nodeId,ancestors,descendants],// SAT generations,clearViewport], //, generations],
 
    
    queryFn: ({ signal }) =>
      fetchGraphCompoundNodesByFilenameAndNodeId<CompoundVertex[]>({  signal,
        filename, 
        node: nodeId,
        generations,
        options: {
          ancestors: ancestors,
          descendants: descendants
        }}),
  });
  

  return { data, isLoading, error };
};

export const useGraphQueryCompoundNodesByNameWithFilename  = (filename: string,
  nodeName: string, 
  keyword : string,
  generations: number,
  ancestors: boolean, descendants: boolean,clearViewport: boolean) => {
  // Return early if nodeName is null or an empty string
  if (!nodeName && !keyword) {
    return { data: undefined, isLoading: false, error: null };
  }  

  console.log("useGraphQueryCompoundNodesByNameWithFilename: ",filename, " nodeName: ", nodeName, " keyword: ", keyword);
  const { data, isLoading, error } = useQuery({
    queryKey: ["filename-and-compound-vertex-name", filename, nodeName,keyword,generations,clearViewport],

    queryFn: ({ signal }) =>
      fetchGraphCompoundNodesByFilenameAndNodeName<CompoundVertex[]>({  signal,
        filename, 
        node: nodeName,
        keyword: keyword,
        generations,
        options: {
          ancestors: ancestors,
          descendants: descendants
        }}),
  });
//  console.log("fetchGraphCompoundNodesByFilenameAndRootName returned the following data ", JSON.stringify(data));
  return { data, isLoading, error };
};