import { useEffect, RefObject } from 'react';
import cytoscape, { LayoutOptions } from 'cytoscape';

export const useCytoscapeLayout = (
  cyRef: RefObject<cytoscape.Core>, // Type RefObject for a Cytoscape instance
  initialLayout: LayoutOptions, // LayoutOptions from Cytoscape
  shouldFit: boolean, // A boolean to indicate if the layout should be fitted
  layoutModified: number,
  onLayoutStop: () => void,
): void => {
  useEffect(() => {
    if (cyRef.current) {
      console.log("useCytoscapeLayout 1. Boom. shouldFit ", shouldFit, " layoutModifed ", layoutModified);
      const layoutOptions: LayoutOptions = {
        ...initialLayout, // Spread in the default layout options
        // @ts-ignore
        fit: shouldFit, // Override the fit option
      };
      const layout = cyRef.current.layout(layoutOptions);
      // Check if shouldFit is true and register 'layoutstop' event if needed
      if (shouldFit) {
          cyRef.current.one('layoutstop', function() {
          onLayoutStop(); // Call the callback provided by the parent component
        });
      }
      console.log("Running layout");
	layout.run();
    }
//  }, [cyRef, shouldFit,layoutModified,onLayoutStop]); //,initialLayout,layoutModified]); // Include all props that could influence layout recalculation
  }, [cyRef, layoutModified, onLayoutStop]); //,initialLayout,layoutModified]); // Include all props that could influence layout recalculation
};
