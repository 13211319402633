import { getAccessToken } from "./getAccessToken";


/**
 * authorizedFetch get the user's login credentials,
 * creates the Authorization header using said tokens,
 * and does the fetch() request
 * 
 * @param url 
 * @param options 
 * @returns 
 */

const authorizedFetch = (url: string, 
    options: RequestInit = {}
    ): Promise<Response> => {

    const token = getAccessToken();

    // Set up default headers using ES6 object spread syntax
    const defaultHeaders: HeadersInit = {
        'Authorization': `Bearer ${token}`,  // Template literals for the token
        ...options.headers,                        // Spread any existing headers from options
    };

    // Combine default and user-provided options with headers using object spread
    const defaultOptions: RequestInit = {
        ...options,
        headers: new Headers(defaultHeaders),
    };

    // Call the original fetch with the URL and the combined options
    return fetch(url, defaultOptions);
};

export default authorizedFetch;


