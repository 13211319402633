import { create } from "zustand";
import { NavbarElements, NavbarsStore } from "../types/interfaces";
import { createJSONStorage, persist } from "zustand/middleware";

// if we're only ever updating the whole state, and not updatting
// only pieces, we won't use a callback ()
//         set({navbarElements: navbarElements}),
export const useNavbarsStore = create<NavbarsStore>()(
  persist(
    (set) => ({
      navbarElements: null,  
      setNavbarElements: (navbarElements: NavbarElements) => 
        set((state) => ({
          ...state,
          navbarElements: navbarElements
        })),
      clear: () => set({ navbarElements: null}),
    }),
    {
      name: 'pumakuna-navbars', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },    
  ));