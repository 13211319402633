import React from "react";

import {
  Button,
  NumberInput,
  Select,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals } from "@mantine/modals";

import "@mantine/core/styles.css";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useCategoriesQuery } from "../../hooks/useCategoriesQuery";
import { useProductQuery } from "../../hooks/useProductsQuery";
import { useNotificationStore } from "../../store/useNotificationStore";
import { Category, Product } from "../../types/interfaces";
import { SelectCreatable } from "../selects/SelectCreatable";
import { addNewProduct } from "../../util/http";
import { useLoginStore } from "../../store/useLoginStore";

export interface AddProductRequest {
  name: string;
  description: string;
  price: number;
  deliveryTime: number;
  imageUrl: string;
  isAvailable: boolean;
  category: Category | undefined;
}

const ProductForm = () => {
  const [isAvailable, setIsAvailable] = useState(true);
  const notificationStore = useNotificationStore();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  const { mutate, isPending } = useMutation({
    mutationFn: (requestBody: { productRequest: AddProductRequest }) =>
      addNewProduct({
        accessToken: token,
        productRequest: requestBody.productRequest,
      }), //requestBody }),

    // For waiting to close until the data is written
    onSuccess: () => {
      // call query client to force a refresh of the query, so we'll get an
      // immediate refresh
      queryClient.invalidateQueries({ queryKey: ["products"] });
      closeAllModals();
      notificationStore.successNotification("Added product successfully");
    },

    onError: (error: Error) => {
      notificationStore.errorNotification(error?.message, "Cannot add product");
    },
  });

  const productForm = useForm({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      deliveryTime: 2,
      imageUrl: "",
    },
    validate: {
      price: (value: number) =>
        value > 0 ? null : "Price cannot be less than 0",
    },
  });

  const productsQuery = useProductQuery(false);
  const categoriesQuery = useCategoriesQuery();

  const handleSubmit = (requestBody: AddProductRequest) => {
    console.log("handleSubmit is " + requestBody);
    mutate({ productRequest: requestBody });
  };

  if (categoriesQuery.isLoading) {
    return <></>;
  }

  const categories = categoriesQuery.data!.map((category) => {
    return category.name;
  });

  return (
    <form
      onSubmit={productForm.onSubmit((values) => {
        handleSubmit({
          ...values,
          isAvailable,
          category: categoriesQuery.data!.find(
            (category) => category.name === selectedCategory
          ),
        });
      })}
    >
      <TextInput
        withAsterisk
        required
        label="Name"
        {...productForm.getInputProps("name")}
        type="text"
      />
      <Textarea
        withAsterisk
        required
        label="Description"
        {...productForm.getInputProps("description")}
        autosize
        minRows={2}
      />
      <NumberInput
        withAsterisk
        required
        label="Price in &#8377;"
        {...productForm.getInputProps("price")}
      />
      <NumberInput
        withAsterisk
        required
        label="Delivery time in days"
        {...productForm.getInputProps("deliveryTime")}
      />
      <SelectCreatable
        listData={categories}
        selectedValue={selectedCategory}
        setSelectedValue={setSelectedCategory}
      />
      <TextInput
        withAsterisk
        required
        label="Image url"
        {...productForm.getInputProps("imageUrl")}
      />
      <Switch
        label="Is in stock?"
        checked={isAvailable}
        onChange={(event) => setIsAvailable(event.currentTarget.checked)}
      />
      {!isPending && (
        <Button type="submit" mt={"lg"}>
          Submit
        </Button>
      )}
    </form>
  );
};

export default ProductForm;
