import React, { useEffect, useRef } from 'react';
import mermaid, { RenderResult } from 'mermaid';
import { Box, Center, Container, MantineTheme, Title, useMantineTheme } from '@mantine/core';

// Set up Mermaid: disable automatic rendering since we'll handle it manually
mermaid.initialize({ startOnLoad: false });

interface MermaidDiagramProps {
  diagramDefinition: string;
}

//const MermaidDiagram = ({ diagramDefinition }: MermaidDiagramProps) => {
const MermaidDiagram = () => {  
  const containerRef = useRef<HTMLDivElement>(null); // Using ref for the container
  const theme = useMantineTheme();  

 // Hardcoded diagram definition with the label included
 const diagramDefinition: string = `
 erDiagram
     USER {
       int id PK
       string name
       string email NN
       string password NN
     }

     EMAIL_ADDRESS {
       int id PK
       int user_id FK
       string address NN
       bool verified NN
     }

     USER ||--o{ EMAIL_ADDRESS : has
 `;
  useEffect(() => {
    const renderMermaid = async () => {
      if (containerRef.current) {
        try {
          // Call mermaid.render and await the promised RenderResult object
          const result: RenderResult = await mermaid.render('mermaid-diagram', diagramDefinition);
          // Set the innerHTML of the container with the svg content from RenderResult
          containerRef.current.innerHTML = result.svg;
        } catch (error) {
          console.error("Failed to render Mermaid diagram:", error);
        }
      }
    };

    renderMermaid();
  }, [diagramDefinition]);
//}, []);  

/**
 * In this update, the Title component is separated from the Center component. I
 * have applied styles directly to the Title to align the text center horizontally
 * and added some margin to reduce the white space.
 * 
 * The Center component is used only for the div housing the Mermaid diagram,
 * making sure the diagram's content itself is centered horizontally while allowing
 * it to maintain its natural position from the top of the page.
 * 
 * Adjust the value of margin in the Title style to reduce or increase the spacing
 * as needed. The example i've provided adds 20 pixels of margin on the top and
 * bottom which is typically a good starting point if you want to reduce
 * significant white space.
 */
 
  return (
    <Container>
      <Title order={2} style={{ textAlign: 'center', margin: '20px 0' }}>
        My Database Schema
      </Title>
      <Center style={{ textAlign: 'center' }}>
        <div ref={containerRef} className="mermaid" />
      </Center>      
  </Container>    
  );
};

export default MermaidDiagram;