import React, { useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { Button, Paper, Grid, Container } from '@mantine/core';

const CytoscapeWithMantine = () => {
  const container = useRef(null);
  const [isGraphVisible, setIsGraphVisible] = useState(false);

  const generateGraph = () => {
    if (!container.current) return;

    // Initialize Cytoscape
    cytoscape({
      container: container.current,
      elements: [
        { data: { id: 'node1' } },
        { data: { id: 'node2' } },
        { data: { id: 'edge1', source: 'node1', target: 'node2' } }
      ],
      layout: {
        name: 'grid',
        rows: 1
      },
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#666',
            label: 'data(id)'
          }
        },
        {
          selector: 'edge',
          style: {
            'line-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': '#ccc'
          }
        }
      ]
    });
    setIsGraphVisible(true);
  };

  return (
    <Container style={{ minHeight: '100vh' }} size="md">
      <Grid style={{ margin: '2em 0' }}>
        <Grid.Col>
          <Paper p="md">
            {!isGraphVisible && (
              <Button onClick={generateGraph}>Generate graph</Button>
            )}
            <div 
              id="cy"
              style={{ width: '100%', height: '500px' }}
              ref={container} 
            />
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default CytoscapeWithMantine;