import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLoginStore } from "../../store/useLoginStore";


// Routes that require login credentials. 
// See: https://medium.com/@dennisivy/creating-protected-routes-with-react-router-v6-2c4bbaf7bc1c
// The protected routes are nested in the Outlet. See the calling component
// (typically App.js) to see those nested routes are.

const PrivateRoutes = () => {
  const { isLogged } = useLoginStore();
  // On refresh, this was always _false_ :(. I fixed it by saving
  // session state in the zustand hook. See store/useLoginStore
  console.log("PrivateRoutes isLogged = " + isLogged);

  return isLogged ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoutes;
