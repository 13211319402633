import React, { useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { Button, Paper, Grid, Container, ColorPicker } from '@mantine/core';

const CytoscapeWithMantine3 = () => {
  const container = useRef(null);
  const [cy, setCy] = useState<cytoscape.Core|null>(null);
  const [color, setColor] = useState('#2CCCE4');  // Set initial color

  useEffect(() => {
    if (!container.current) return;

    const cyInstance = cytoscape({
      container: container.current,
      elements: [
        { data: { id: 'node1', label: 'Node 1', color: '#2CCCE4' } }
      ],
      layout: {
        name: 'circle'
      },
      style: [
        {
          selector: 'node',
          style: {
            label: 'data(label)',
            'background-color': 'data(color)'
          }
        }
      ]
    });

    setCy(cyInstance);
  }, []);

  const addNode = () => {
    if (!cy) return;

    const nextId = cy.nodes().length + 1;
    cy.add([
      { group: 'nodes', data: { id: `node${nextId}`, label: `Node ${nextId}`, color: color } }
    ]);

    cy.layout({ name: 'circle' }).run();
  };

  return (
    <Container style={{ minHeight: '100vh' }} size="md">
      <Grid style={{ margin: '2em 0' }}>
        <Grid.Col>
          <ColorPicker color={color} onChange={setColor} />
          <Button onClick={addNode} style={{marginTop: '1em'}}>Add Node</Button>
        </Grid.Col>
        <Grid.Col>
          <Paper p="md">
            <div ref={container} style={{ width: '100%', height: '500px' }} />
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default CytoscapeWithMantine3;