import  { useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { Button, Paper, Notification } from '@mantine/core';

const CytoscapeWithMantine2 = () => {
  const container = useRef(null);
  const [cy, setCy] = useState<cytoscape.Core|null>(null);

  useEffect(() => {
    if (!container.current) return;

    const cyInstance :cytoscape.Core = cytoscape({
      container: container.current,
      elements: [
        { data: { id: 'node1', label: 'Node 1' } }
      ],
      layout: {
        name: 'circle'
      },
      style: [
        {
          selector: 'node',
          style: {
            label: 'data(label)'
          }
        }
      ]
    });

    setCy(cyInstance);
  }, []);

  const addNode = () => {
    if (!cy) return;

    const nextId = cy.nodes().length + 1;
    cy.add([
      { group: 'nodes', data: { id: `node${nextId}`, label: `Node ${nextId}` } }
    ]);

    cy.layout({ name: 'circle' }).run();
  };

  return (
    <>
      <div style={{ margin: '1em' }}>
        <Button onClick={addNode}>Add Node</Button>
      </div>
      <div ref={container} style={{ width: '100%', height: '80vh' }} />
      <div style={{ margin: '1em' }}>
        <Notification>
          Click "Add Node" to add a new node into the graph.
        </Notification>
      </div>
    </>
  );
};
export default CytoscapeWithMantine2;