import React from "react";

import { ActionIcon } from "@mantine/core";

import "@mantine/core/styles.css";

import { IconTrash } from "@tabler/icons";

// Move the mutation state of deleting a record
import { useProductDelete } from "../../hooks/useProductDelete";

//Warning: Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
// I think this was caused because in http.ts, const { accessToken } = useLoginStore();
// was crashing because it was null. I need to figure out how to use it.
// See: https://github.com/typescript-cheatsheets/react#reacttypescript-cheatsheets
const DeleteProductButton = ({ productId }: { productId: number }) => {

  const { productDelete, isPending } = useProductDelete();

  function handleOnClick(id: number) {
    console.log("You clicked me! " + id);

   // The next fires the useMutation, mutationFn, which has been aliased to "productDelete",
   // which is in the useProductDelete hook
    productDelete(productId);
  }

  return (
    <ActionIcon
      color={"red"}
      onClick={() => {
        handleOnClick(productId);
      }}
    >
      <IconTrash />
      {isPending && "Deleting..."}
    </ActionIcon>
  );
};

export default DeleteProductButton;
