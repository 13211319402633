import React, { useEffect, useRef, useState } from 'react';
import cytoscape from 'cytoscape';
import { Core, NodeDefinition, EdgeDefinition, NodeSingular } from 'cytoscape';
//@ts-ignore
import coseBilkent from 'cytoscape-cose-bilkent';
import { Button, Paper, Grid, Container, ColorPicker, TextInput } from '@mantine/core';

cytoscape.use(coseBilkent);

const GraphComponent = () => {
  const container = useRef(null);
  const [cy, setCy] = useState<Core | null>(null);
  const [backgroundColor, setBackgroundColor] = useState('#2CCCE4');
  const [newNodeId, setNewNodeId] = useState<string>('2');

  useEffect(() => {
    if (!container.current) return;

    const cyInstance: Core = cytoscape({
      container: container.current,
      elements: [
        { data: { id: '1', label: 'Node 1', color: '#2CCCE4' } }
      ],
      style: [
        {
          selector: 'node',
          style: {
            'background-color': 'data(color)',
            label: 'data(label)'
          }
        },
        {
          selector: 'edge',
          style: {
            'line-color': '#ccc'
          }
        }
      ],
      layout: {
        name: 'cose-bilkent',
      }
    });

    setCy(cyInstance);
  }, []);

  const addNode = () => {
    if (!cy) return;

    cy.add([
      { group: 'nodes', data: { id: newNodeId, label: `Node ${newNodeId}`, color: backgroundColor } }
    ]);

    cy.elements().layout({ name: 'cose-bilkent',  }).run();

    // Prepare for the next node
    setNewNodeId((prevId) => (parseInt(prevId) + 1).toString());
  };

  return (
    <Container style={{ minHeight: '100vh' }} size="lg">
      <Grid gutter="lg">
        <Grid.Col span={8}>
          <ColorPicker color={backgroundColor} onChange={setBackgroundColor} size="xl" />
          <TextInput label="Node id" 
            value={newNodeId} 
            onChange={(event) => setNewNodeId(event.currentTarget.value)}             
            mt="md" />
          <Button mt="md" onClick={addNode}>Add Node</Button>
        </Grid.Col>
        <Grid.Col span={16}>
          <Paper p="md" style={{ height: '500px' }}>
            <div ref={container} style={{ width: '100%', height: '100%' }} />
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default GraphComponent;