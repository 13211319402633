import {
  Button,
  Grid,
  Image,
  NumberInput,
  Select,
  Stack,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import "@mantine/core/styles.css";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import LoadingSpinner from "../../components/display/LoadingSpinner";
import { useCategoriesQuery } from "../../hooks/useCategoriesQuery";
import { useNotificationStore } from "../../store/useNotificationStore";
import { Category, Product } from "../../types/interfaces";
import { SelectCreatable } from "../../components/selects/SelectCreatable";
import { useLoginStore } from "../../store/useLoginStore";
import { updateProduct } from "../../util/http";

export interface UpdateProductRequest {
  id: number;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  isAvailable: boolean;
  deliveryTime: number;
  category: Category;
}

const ProductDetailsAdminManagerView = ({ product }: { product: Product }) => {
  const categoriesQuery = useCategoriesQuery();
  const { name, description, price, deliveryTime } = product;
  const [isAvailable, setIsAvailable] = useState(product.isAvailable);
  const [imageUrl, setImageUrl] = useState(product.imageUrl);
  const notificationStore = useNotificationStore();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    product.category.name
  );
  const queryClient = useQueryClient();

  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  const form = useForm({
    initialValues: {
      name,
      description,
      price,
      deliveryTime,
    },
  });

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (requestBody: UpdateProductRequest) =>
      updateProduct({ requestBody, productId: product.id, accessToken: token }),

    onSuccess: (updatedProduct: Product) => {
      // ADT adding next
      notificationStore.successNotification(
        "Product " + updatedProduct.name + " updated successfully"
      );
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
    onError: (data: Error) => {
      notificationStore.errorNotification(
        data.message,
        `Could not update product `
      );
    },
  });

  // ADT NumberInput precision is now decimalScale
  // https://mantine.dev/core/number-input/

  if (categoriesQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const categories = categoriesQuery.data!.map((category) => {
    return category.name;
  });

  return (
    <>
      <Grid.Col span={3} offset={2}>
        <TextInput
          withAsterisk
          required
          label="Link to image"
          value={imageUrl}
          onChange={(event) => setImageUrl(event.currentTarget.value)}
        />
        <Image alt={name} src={imageUrl} height={500} fit="contain" />
      </Grid.Col>
      <Grid.Col span={7} offset={1}>
        <form
          onSubmit={form.onSubmit((values) => {
            const category = categoriesQuery.data!.find(
              (category) => category.name === selectedCategory
            ) as Category;

            mutate({
              ...values,
              id: product.id,
              isAvailable,
              imageUrl,
              category,
            });
          })}
        >
          <Stack align={"flex-start"}>
            <TextInput
              withAsterisk
              required
              label="Name"
              {...form.getInputProps("name")}
              w={"40%"}
            />
            <Switch
              label="Is in stock?"
              checked={isAvailable}
              onChange={(event) => setIsAvailable(event.currentTarget.checked)}
            />
            <Textarea
              withAsterisk
              w={"70%"}
              required
              autosize
              label="Description"
              {...form.getInputProps("description")}
            />
            <SelectCreatable
              listData={categories}
              selectedValue={selectedCategory}
              setSelectedValue={setSelectedCategory}
            />
            <NumberInput
              withAsterisk
              required
              decimalScale={2}
              label="Price in &#8377;"
              min={1}
              step={50}
              {...form.getInputProps("price")}
            />
            <NumberInput
              withAsterisk
              required
              label="Delivery time in days"
              min={1}
              {...form.getInputProps("deliveryTime")}
            />
            <Button type="submit">Apply edits</Button>
          </Stack>
        </form>
      </Grid.Col>
    </>
  );
};

export default ProductDetailsAdminManagerView;
