import React from "react";
import {
  ActionIcon,
  Button,
  Container,
  Group,
  List,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { openConfirmModal, openModal } from "@mantine/modals";

import "@mantine/core/styles.css";

import { IconTrash, IconUserPlus } from "@tabler/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotificationStore } from "../../store/useNotificationStore";
import { Customer } from "../../types/interfaces";
import LoadingSpinner from "../display/LoadingSpinner";
import AddCustomerForm from "../forms/AddCustomerForm";
import { useProfileDelete } from "../../hooks/useProfileDelete";
import { useCustomersQuery } from "../../hooks/useCustomersQuery";

const ManageCustomers = () => {
  const notificationStore = useNotificationStore();
  const {profileDelete, isPending: isPendingProfile} = useProfileDelete();

  const queryClient = useQueryClient();
 
  // Note: v5 of useQuery does not have the callbacks onSuccess, onError, etc.
  // See: 
  // https://github.com/TanStack/query/discussions/5279  
  // and the blog post it references:
  // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose 

  const {data:customerData,isLoading: isLoadingCustomers, isError} = useCustomersQuery();

  if (isLoadingCustomers) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Paper withBorder shadow={"xl"} p={"xl"} m={"xl"}>
        <Title order={2} mb={"md"}>
          List of all customers
        </Title>
        <Group align="center">
          <Button
            type="button"
            leftSection={<IconUserPlus />}
            variant="subtle"
            onClick={() => {
              openModal({
                title: "Add a new customer",
                children: <AddCustomerForm />,
                centered: true,
              });
            }}
          >
            Add new customer
          </Button>
        </Group>
        <List withPadding listStyleType={"none"}>
          {customerData.length > 0 &&
            customerData.map((customer:Customer) => {

              return (
                <List.Item key={customer.id}>
                  <Group>
                    <ActionIcon
                      color={"red"}
                      size={"lg"}
                      onClick={() => {
                        openConfirmModal({
                          title: "Are you sure",
                          labels: {
                            confirm: "Yes",
                            cancel: "No",
                          },
                          centered: true,
                          confirmProps: {
                            color: "red",
                          },
                          onConfirm: () => {
                            profileDelete({profileType: "customers", profileId: customer.id})
                          },
                        });
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                    <Text>{customer.name}</Text>
                  </Group>
                </List.Item>
              );
            })}
        </List>
      </Paper>
    </Container>
  );
};

export default ManageCustomers;
