import { ReactNode } from 'react';
import styles from './NavbarSimple.module.css';


const FooterSimple = ({
    children } : {
    children : ReactNode;    
}) => {
    return <div className={styles.footer}>{children}</div>;
}

export default FooterSimple;