import { LayoutOptions } from "cytoscape";


/**
 * These values should be experimented with, along with other values
 * that may be useful.
 * See: https://github.com/cytoscape/cytoscape.js-klay
 */
export const layoutOptions : LayoutOptions = {
     name: 'klay',

      // @ts-ignore
      nodeDimensionsIncludeLabels: false, // Dont consider the label size in the layout
  //    fit: false,        
//      fit:true,
      animate: false,         
//      padding: 20,     
      klay: {
        // aspectRatio: 1.5, ?
        spacing: 5, // Define the spacing between elements
        borderSpacing: 5, // Space between node border and children
        // Include other relevant options for your layout
      },
      // Define padding for the entire layout if necessary. 20 
      // is actually the default
      padding: 20,


    };