import React from "react";
import { Center, Loader } from "@mantine/core";

import "@mantine/core/styles.css";

const LoadingSpinner = () => {
  return (
    <Center h="80vh">
      <Loader size={"md"} />
    </Center>
  );
};

export default LoadingSpinner;
