import React, { useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';
import Draggable from 'react-draggable';

const DraggableModal4 = () => {
  const [opened, setOpened] = useState(false);

  // Function to handle the modal opening
  const openModal = () => {
    setOpened(true);
  };

  // Function to handle the modal closing
  const closeModal = () => {
    setOpened(false);
  };

  return (
    <>
      <Button onClick={openModal}>Open Draggable Modal</Button>
      {opened && (
        <Draggable>
          <Modal
            opened={opened}
            onClose={closeModal}
            withCloseButton={false}
            centered={false}  // Disable auto centering to allow dragging
            closeOnClickOutside={false}
            withinPortal={false}  // Render Modal outside of Portal to enable dragging
            styles={{
                header: {
                  margin: 0,
                },
                close: {
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  boxShadow: '0 0 14px rgba(0,0,0,0.1)',
                },
            }}
          >
            <Text>This is a draggable Mantine modal. You can drag it by holding down the modal area.</Text>
          </Modal>
        </Draggable>
      )}
    </>
  );
};

export default DraggableModal4;