import React, { useState } from 'react';
import { Modal, Text, Group, Button } from '@mantine/core';
import Draggable from 'react-draggable';

const DraggableModal3 = () => {
  const [opened, setOpened] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // Function to open the modal
  const openModal = () => {
    setOpened(true);
    setDisabled(false); // Enable the draggable functionality
  };

  // Function to close the modal
  const closeModal = () => {
    setOpened(false);
    setDisabled(true); // Disable the draggable functionality to prevent errors
  };

  return (
    <>
      <Button onClick={openModal}>Open Draggable Modal</Button>
      <Draggable handle=".draggable-handle" disabled={disabled}>
        <div>
          <Modal
            opened={opened}
            onClose={closeModal}
            title={<div className="draggable-handle" style={{ cursor: 'move' }}>Draggable Modal</div>}
            withCloseButton={false}
            centered={false}
          >
            <Text>This is a draggable Mantine modal.</Text>
            <Group p="right" mt="md">
              <Button onClick={closeModal}>Close</Button>
            </Group>
          </Modal>
        </div>
      </Draggable>
    </>
  );
};

export default DraggableModal3;