import { Button, NumberInput, Stack } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import React, { useState } from "react";
import { useProfileDataQuery } from "../../hooks/useProfileDataQuery";
import { useNotificationStore } from "../../store/useNotificationStore";

const TopupWallet = () => {
  const notificationStore = useNotificationStore();
  const [amount, setAmount] = useState(100);

  const profileDataQuery = useProfileDataQuery();

  // ADT tanstack v5, converting parms into object
  // https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5
  const topupWalletMutation = useMutation({
    mutationFn: (requestBody: { amount: number }) => {
      return axios.patch(
        `${import.meta.env.VITE_APP_BACKEND_URL}/customers/topup`,
        requestBody
      );
    },
    onSuccess: () => {
      profileDataQuery.refetch();
      closeAllModals();
      notificationStore.successNotification("Amount added successfully");
    },
    onError: (data: AxiosError) => {
      notificationStore.errorNotification(
        data.message,
        "Could not topup wallet"
      );
    },
  });

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          topupWalletMutation.mutate({ amount });
        }}
      >
        <Stack>
          <NumberInput
            min={100}
            required
            step={50}
            size="md"
            withAsterisk
            label="Amount in &#8377;"
            value={amount}
            onChange={(value) => setAmount(value as number)}
          />
          <Button type="submit">Submit</Button>
        </Stack>
      </form>
    </>
  );
};

export default TopupWallet;
