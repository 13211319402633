import React, { useState, useEffect } from 'react';
import cytoscape from 'cytoscape';
import { Modal } from '@mantine/core';

/**
 * If you click on the node, a modal opens up
 * @returns 
 */
const CytoscapeWithMantine13 = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<cytoscape.NodeSingular | null>(null);

  useEffect(() => {
    // Initialize your cytoscape instance
    const cy = cytoscape({
      container: document.getElementById('cy'),
      elements: [{ data: { id: 'node1', label: 'Node 1',value : 'hello world' } }],
      style: [
        {
          selector: 'node',
          style: {
            label: 'data(label)',
            'shape': 'ellipse', // make the node shape a circle              
            'height': 75,
            'width': 75, // adjust size as per your requirement              
            'background-color': '#2CCCE4',
            'text-valign': 'center',
            'text-halign': 'center',     
            'color': '#fff',
            'text-outline-width': 2,
            'text-outline-color': '#37a'                       
          }
        },
        {
          selector: 'edge',
          style: {
            'line-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': '#ccc'
          }
        }
      ]      
      //style: [ // your styles ],
    });

    cy.on('tap', 'node', (event) => {
      setSelectedNode(event.target);
      setModalOpen(true);
    });
  }, []);

  return (
    <div>
      <div id="cy" style={{ width: "100%", height: "400px" }} />
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Selected Node"
      >
        {selectedNode && <><p>Node ID: {selectedNode.id()}</p><p>Other cool info: {selectedNode.data().value}</p></>}
      </Modal>
    </div>
  );
};

export default CytoscapeWithMantine13;