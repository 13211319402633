import React from "react";

import {
  Container,
  Paper,
  Title,
  Stack,
  TextInput,
  Group,
  Button,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import "@mantine/core/styles.css";
import { useMutation } from "@tanstack/react-query";
import { updateProfile } from "../../util/http";

import { useProfileDataQuery } from "../../hooks/useProfileDataQuery";
import { useLoginStore } from "../../store/useLoginStore";
import { useNotificationStore } from "../../store/useNotificationStore";
import { User } from "../../types/interfaces";
import LoadingSpinner from "../display/LoadingSpinner";

const AdminManagerProfile = () => {
  const profileDataQuery = useProfileDataQuery();

  const notificationStore = useNotificationStore();
  const { accessToken, authority } = useLoginStore();
  const token = accessToken || "";

  const { mutate, isPending } = useMutation({
    mutationFn: (requestBody: User) =>
      updateProfile<User>({
        requestBody: requestBody,
        authority: authority,
        accessToken: token,
      }),

    // For waiting to close until the data is written
    onSuccess: () => {
      notificationStore.successNotification("Profile Update Successful");
      profileDataQuery.refetch();
    },

    onError: (error: Error) => {
      notificationStore.errorNotification(
        error?.message,
        `Profile Update failed`
      );
    },
  });

  if (profileDataQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const form = useForm({
    initialValues: {
      id: profileDataQuery.data!.id,
      name: profileDataQuery.data!.name,
      email: profileDataQuery.data!.email,
      role: profileDataQuery.data!.role,
      phoneNumber: profileDataQuery.data!.phoneNumber,
      createdAt: profileDataQuery.data!.createdAt,
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
    },
  });
  return (
    <Container p={"xl"} maw="50%">
      <Paper withBorder shadow={"xl"} p={"xl"} m={"xl"}>
        <Title order={2} tt="capitalize" c={"deepBlue"} ta="center" mb={"md"}>
          Profile Details
        </Title>
        <Stack>
          <Text size={"lg"}>
            <strong>Name:</strong> {profileDataQuery.data!.name}
          </Text>
          <Text size={"lg"}>
            <strong>Phone number:</strong> {profileDataQuery.data!.phoneNumber}
          </Text>
          <Text size={"lg"}>
            <strong>Email:</strong> {profileDataQuery.data!.email}
          </Text>
          <Text size={"lg"} fw={700}>
            Update Profile:
          </Text>
          <form
            onSubmit={form.onSubmit((values) => {
              mutate(values);
            })}
          >
            <Stack>
              <TextInput
                withAsterisk
                required
                label="Name"
                placeholder="New name"
                {...form.getInputProps("name")}
                type={"text"}
                size={"md"}
              />
              <TextInput
                withAsterisk
                required
                label="Phone Number"
                placeholder="New phone number"
                {...form.getInputProps("phoneNumber")}
                type={"text"}
                size={"md"}
              />
              <Group ta={"center"}>
                <Button type="submit" size="sm">
                  Update Details
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
};

export default AdminManagerProfile;
