import React, {
    createContext,
    Dispatch,
    ReactNode,
    MutableRefObject,
    useContext,
    useReducer,
  } from 'react';
  import { ActionTypes } from '../types/enums';
  
  // The action interface for setting and removing refs
  interface RefAction<T> {
    type: ActionTypes;
    key: string;
    ref?: MutableRefObject<T>;
  }
  
  // Define the state type with string keys and possibly undefined MutableRefObject values
  type RefStoreState<T> = {
    [key: string]: MutableRefObject<T> | undefined;
  };
  
  // Define the shape of our context
  interface RefContextProps<T> {
    refStore: RefStoreState<T>;
    dispatch: Dispatch<RefAction<T>>;
  }
  
  // Create the context with an initial value of undefined
  const RefContext = createContext<RefContextProps<any> | undefined>(undefined);
  
  // useReducer setup with a generic state and action
  const refStoreReducer = <T,>(
    state: RefStoreState<T>,
    action: RefAction<T>
  ): RefStoreState<T> => {
    switch (action.type) {
      case ActionTypes.SetRef:
        // Set the ref to the provided key if `ref` is defined
        //return { ...state, [action.key]: action.ref };      // If `action.ref` is defined, set it; otherwise, leave it as undefined
        return { ...state, [action.key]: action.ref };

        //return { ...state, [action.key]: action.ref || {} }; // If ref is undefined, assign an empty object        
      case ActionTypes.RemoveRef:
        // Omit the given key from the state to remove the ref
        const { [action.key]: _, ...newState } = state;
        return newState;
      default:
        // Return the current state by default
        return state;
    }
  };
  
  // Custom hook for using the RefContext
  export const useRefStore = <T,>(): RefContextProps<T> => {
    const context = useContext(RefContext);
    if (!context) {
      // If the context is not available, throw an error
      throw new Error('useRefStore must be used within a RefContext.Provider');
    }
    // Return the context with a specific type
    //return context as RefContextProps<T>;
    return context as RefContextProps<T>;
  };
  
  // Props interface for the AppProvider
  interface AppProviderProps {
    children: ReactNode;
  }
  
  // The AppProvider component wrapping its children with the provided context
  export const AppProvider = ({ children }: AppProviderProps) => {
    // Initialize the refStore with an empty object
    const [refStore, dispatch] = useReducer(refStoreReducer, {});
  
    // Prepare the context value
    const contextValue: RefContextProps<any> = { refStore, dispatch };
  
    // Render the context provider with the contextValue
    return (
      <RefContext.Provider value={contextValue}>
        {children}
      </RefContext.Provider>
    );
  };