import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { Category } from "../types/interfaces";
import { fetchCategories } from "../util/http";

export const useCategoriesQuery = (): UseQueryResult<Category[]> => {

  return useQuery({
    queryKey: ["categories"],

    queryFn: ({ signal }) => fetchCategories({ signal }),
  });
};
