import { TerminalNode, TerminalNodesAndLabel } from "../../types/interfaces";

/**
 * Render a thumbnail . Kind of hokey, since it's only 
 * depending upon a label named "thumbnail", and if that's
 * the name assume the value is a base 64 encoded image.
 * @param param0 
 * @returns 
 */
const ThumbnailRenderer = ({nodes} :
  {nodes: TerminalNode[]}) => {

    // Find the node with the name 'thumbnail'
  const thumbnailNode = nodes.find(node => node.name === 'thumbnail');

  // If the thumbnail node exists and has a value, render the image
  if (thumbnailNode && thumbnailNode.value) {
    const base64String = 'data:image/png;base64, ' + thumbnailNode.value;
    return <img src={base64String} alt="Thumbnail" />;
  }

  // If no thumbnail node is found or it doesn't have a value, return null
  return null;    
}    

export default ThumbnailRenderer;