import React, { useState, useEffect } from 'react';
import { Drawer, Button, CloseIcon } from '@mantine/core';
import { NodeDrawerProps } from '../../types/interfaces';

const NodeDrawer = ({ isOpen, closeDrawer}:NodeDrawerProps) => {
  // Optional: Add local state for any drawer-specific logic

  useEffect(() => {
    // Perform actions based on isOpen changes (e.g., focus management)
  }, [isOpen]);

  return (
    <Drawer 
    opened={isOpen} 
    onClose={closeDrawer} 
    size="xs" 
    padding="md"
    position="left"
    title={
      <Button onClick={closeDrawer}>
        <CloseIcon style={{ marginRight: '1em' }} />
        Close Drawer
      </Button>
    }
  >
    {/* Drawer Content */}
  </Drawer>   
  );
}; 
  {/*
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" title="My Drawer"></Drawer>    
      {children}
    </Drawer>
*/}

export default NodeDrawer;