import { useQuery } from "@tanstack/react-query";
import { useLoginStore } from "../store/useLoginStore";
import { fetchFiles } from "../util/http";

export const useFilesQuery = (enabled: boolean = true) => {
  // "object destructuring" is pulling out one of the return values,
  // enclosing it/them in curly braces.
  const { accessToken } = useLoginStore();
  const token = accessToken || "";

  return useQuery({
    queryKey: ["files"],

    queryFn: ({ signal }) =>
    fetchFiles({
        enabled,
        signal,
        accessToken: token,
      }),
    enabled,
  });
};
