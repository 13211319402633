import { showNotification } from "@mantine/notifications";

import "@mantine/notifications/styles.css";

import React from "react";

import { IconCheck, IconInfoCircle, IconX } from "@tabler/icons";
import { create } from "zustand";
import { NotificationStore } from "../types/interfaces";



const getDateTimeString =(message:string | undefined) =>  {
  // for date/time string manipulation, see:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  const dateTime = new Date();
  const options : Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,    
  };

  // Undefined will go to the locale of the user.
  const dateTimeString = dateTime.toLocaleDateString(undefined, options);  
  const prependedMessage = message ? `${message} ${dateTimeString}` : dateTimeString;
  return prependedMessage;          
};
export const useNotificationStore = create<NotificationStore>()((set) => ({
  successNotification: (message: string, title?: string) => {
    showNotification({
      message, 
      icon: <IconCheck />,
      color: "green",
      title: getDateTimeString(title),
    });
  },
  errorNotification: (message: string, title?: string) => {
    showNotification({
      message,
      color: "red",
      icon: <IconX />,
      title: getDateTimeString(title),
    });
  },
  infoNotification: (message: string, title?: string) => {
    showNotification({
      message,
      color: "blue", // or any other color you see fit
      icon: <IconInfoCircle />, 
      title: getDateTimeString(title),
    });
  },  
}));
