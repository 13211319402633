import React from 'react';
import Draggable from 'react-draggable';

const DraggableComponent= () => {
  return (
    <Draggable>
      <div style={{ padding: '10px', backgroundColor: 'lightblue', cursor: 'move' }}>
        Hello World
      </div>
    </Draggable>
  );
};

export default DraggableComponent;