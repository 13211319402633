import React from "react";
import ReactDOM from "react-dom/client";
//import App from "./App";
//import App from "./App2";
import App from "./App";


/**
 * Getting double rendering with StrictMode, which is confusing me as I debug ...
 * 
 * If you are seeing the initialization code in the useEffect running twice on the 
 * initial load, it could be due to one of the following reasons:
 * 
 * Strict Mode: When using React's Strict Mode, React may intentionally double-invoke
 * certain functions, including render methods and effect functions, to help detect
 * side-effects and potential problems in your application. Ensure that you are not
 * running your application with <React.StrictMode> wrapping your App component
 * during development if you want to avoid the double-invocation for debugging purposes.
 */
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);
