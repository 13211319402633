import React from "react";
import { Anchor, Badge, Card, Group, Image, Text } from "@mantine/core";

import "@mantine/core/styles.css";
import { Link } from "react-router-dom";
import { useLoginStore } from "../../store/useLoginStore";
import { Product } from "../../types/interfaces";
import DeleteProductButton from "../buttons/DeleteProductButton";

// See: https://github.com/typescript-cheatsheets/react#reacttypescript-cheatsheets
const ProductCard = ({ product }: { product: Product }) => {
  const { name, imageUrl, isAvailable, price } = product;
  const { authority } = useLoginStore();

  return (
    <Card shadow={"md"} withBorder h={"100%"}>
      <Card.Section withBorder>
        <Image src={imageUrl} alt={name} height={160} fit="contain" />
      </Card.Section>
      <Group align="apart" mt="md" mb="xs">
        <Text fw={300}>&#8377; {price.toFixed(2)}</Text>
        {isAvailable ? (
          <Badge color="green" variant="light">
            In stock
          </Badge>
        ) : (
          <Badge color="red" variant="light">
            Out of stock
          </Badge>
        )}
        {authority !== "CUSTOMER" && (
          <DeleteProductButton productId={product.id} />
        )}
      </Group>
      <Anchor component={Link} to={`product/${product.id}`}>
        <Text fw={400}>{name}</Text>
      </Anchor>
    </Card>
  );
};

export default ProductCard;
