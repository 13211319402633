import { useLocation } from 'react-router-dom';

/**
 * 
 * Given the current path, should a footer be displayed by
 * AppShell? 
 * 
 * Consider generalizing this, and passing in variables, rather than
 * having it basically hard coded.
 * 
 * Currently only returning true if it matches "file" or "tableau-prep", which for the 
 * moment is the path to the Cytoscape component.
 *
 * EXTREME caution: when I didn't have 'tableau-prep' included as one of the strings
 * to match on, the entire Cytoscape graph did not display! This took hours to
 * figure out.
 *
 */
export const useShouldShowFooter = (): boolean => {
  const { pathname } = useLocation();
  const routePattern: RegExp = /^\/app\/([^\/]+)\/.*/;
  const match: RegExpMatchArray | null = pathname.match(routePattern);

  const footerRoutes: string[] = ['file', 'tableau-prep', 'foo-route2', 'foo-route3'];
  const shouldShowFooter: boolean = match !== null && footerRoutes.includes(match[1]);

  return shouldShowFooter;
};
