import React from "react";

import {
  Button,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import "@mantine/core/styles.css";

import { useMutation } from "@tanstack/react-query";
import { useProfileDataQuery } from "../../hooks/useProfileDataQuery";
import { useNotificationStore } from "../../store/useNotificationStore";
import { updateProfile } from "../../util/http";
import { Customer, User } from "../../types/interfaces";
import LoadingSpinner from "../display/LoadingSpinner";
import { useLoginStore } from "../../store/useLoginStore";

const CustomerProfile = () => {
  const notificationStore = useNotificationStore();
  const profileDataQuery = useProfileDataQuery();
  const { accessToken, authority } = useLoginStore();
  const token = accessToken || "";

  if (profileDataQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const form = useForm({
    initialValues: {
      id: profileDataQuery.data!.id,
      name: profileDataQuery.data!.name,
      email: profileDataQuery.data!.email,
      role: profileDataQuery.data!.role,
      phoneNumber: profileDataQuery.data!.phoneNumber,
      createdAt: profileDataQuery.data!.createdAt,
      moneyInWallet: profileDataQuery.data!.moneyInWallet,
      cart: profileDataQuery.data!.cart,
      orders: profileDataQuery.data!.orders,
      address: profileDataQuery.data!.address,
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (requestBody: Customer) =>
      updateProfile<Customer>({
        requestBody: requestBody,
        authority: authority,
        accessToken: token,
      }),

    // For waiting to close until the data is written
    onSuccess: () => {
      notificationStore.successNotification("Profile Update Successful");
      profileDataQuery.refetch();
    },

    onError: (error: Error) => {
      notificationStore.errorNotification(
        error?.message,
        `Profile Update failed`
      );
    },
  });

  return (
    <Container p={"xl"} maw="50%">
      <Paper withBorder shadow={"xl"} p={"xl"} m={"xl"}>
        <Title order={2} tt="capitalize" c={"deepBlue"} ta="center" mb={"md"}>
          Profile Details
        </Title>
        <Stack>
          <form
            onSubmit={form.onSubmit((values) => {
              mutate(values);
            })}
          >
            <Stack>
              <TextInput
                withAsterisk
                required
                label="Name"
                placeholder="New name"
                {...form.getInputProps("name")}
                type={"text"}
                size={"md"}
              />
              <Text>
                <strong>Email:</strong> {profileDataQuery.data!.email}
              </Text>
              <TextInput
                withAsterisk
                required
                label="Phone Number"
                placeholder="New phone number"
                {...form.getInputProps("phoneNumber")}
                type={"text"}
                size={"md"}
              />
              <Text>
                <strong>Account Balance: </strong>&#8377;
                {profileDataQuery.data!.moneyInWallet}
              </Text>
              <Textarea
                withAsterisk
                required
                label="Address"
                placeholder="New address"
                {...form.getInputProps("address")}
                size={"md"}
              />
              <Group ta={"center"}>
                <Button type="submit" size="sm">
                  Update Details
                </Button>
              </Group>
            </Stack>
          </form>
        </Stack>
      </Paper>
    </Container>
  );
};

export default CustomerProfile;
