import { useQuery } from "@tanstack/react-query";
import { useLoginStore } from "../store/useLoginStore";
import { fetchProfiles } from "../util/http";

export const useProfileDataQuery = () => {
  const { authority, accessToken } = useLoginStore();
  // lower case the authority enum.
  const type = authority?.toLocaleLowerCase();
  const token = accessToken || "";

  // concatenate the lower case profile type (CUSTOMER, MANAGER or ADMIN)
  // with "Profile" to create the key for tanstack react-query's caching key
  const profile = `${type}Profile`;
  console.log("queryKey profile " + profile);

  const queryKeys = [type];

  return useQuery({
    queryKey: queryKeys,

    queryFn: ({ signal }) =>
      fetchProfiles({ profile: type, signal, accessToken: token }),
  });
};
