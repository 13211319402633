import {
  Anchor,
  Button,
  Container,
  Group,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/useLoginStore";
import { useNavbarsStore } from "../../store/useNavbarsStore";
import { useNotificationStore } from "../../store/useNotificationStore";
import { LoginResponse, NavbarElement, NavbarElements, LoginUserRequestBody, RegisterUserRequestBody } from "../../types/interfaces";
import { IconBuildingStore, IconUpload, IconDashboard, IconKey, IconDatabaseImport, Icon2fa, IconSettings, IconShoppingCart } from "@tabler/icons";

const LoginForm = () => {
  const [pageMode, setPageMode] = useState<"Login" | "Register">("Login");

  const {setAccessToken, setAuthority, isLogged } = useLoginStore();

  const notificationStore = useNotificationStore();
  const navbarsStore = useNavbarsStore();

  const navigate = useNavigate();


  const adminLabels : NavbarElement[] = [
    { link: 'products', label: 'Products'},
    { link: 'upload', label: 'Upload'},
    { link: 'uploaded', label: 'Uploaded Files'},
    { link: 'dashboard', label: 'Dashboard'},
    { link: 'keys', label: 'SSH Keys'},
    { link: 'authentication', label: 'Authentication'},
    { link: 'settings', label: 'Other Settings'},
  ];

  const customerLabels : NavbarElement[] = [
    { link: 'products', label: 'Products'},
    { link: 'upload', label: 'Upload'},
    { link: 'uploaded', label: 'Uploaded Files'},    
    { link: 'cart', label: 'Cart'},
    { link: 'settings', label: 'Other Settings'}
  ];

  const customerNavbarElements : NavbarElements = {
    header : customerLabels,
    sidebar: customerLabels,
  };

  const adminNavbarElements : NavbarElements = {
    header : adminLabels,
    sidebar: adminLabels,
};  
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      name: "",
      phoneNumber: "",
      address: "",
    },

    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
      password: (value: string) =>
        value.trim().length < 4 ? "Invalid password" : null,
    },
  });

  console.log("LoginForm isLogged " + isLogged);

  const performAuth = useMutation({
    mutationFn: (requestBody: LoginUserRequestBody | RegisterUserRequestBody) => {
      delete axios.defaults.headers.common["Authorization"];

      return axios.post<LoginResponse>(
        `${
          import.meta.env.VITE_APP_BACKEND_URL
        }/auth/${pageMode.toLowerCase()}`,
        requestBody
      );
    },
    onSuccess: (data) => {
      form.reset();
      if (pageMode === "Login") {
        const { accessToken, scope } = data.data;
        setAccessToken(accessToken);
        setAuthority(scope);
        notificationStore.successNotification("Login Successful");

        if (scope !== "CUSTOMER") {
          navbarsStore.setNavbarElements(adminNavbarElements);
        } else {
          navbarsStore.setNavbarElements(customerNavbarElements);
        }
        navigate("/");
      } else {
        notificationStore.successNotification(
          "Registration successful",
          "Login to continue"
        );
        setPageMode("Login");
        form.reset();
      }
    },
    onError: (data: AxiosError) => {
      notificationStore.errorNotification(data.message, `${pageMode} failed`);
    },
  });

  // v. 7 of Mantine, Text has changed props, from "transform" and "color" to e.g.
  // BAD transform="capitalize", color={"deepBlue"}
  // GOOD tt="capitalize", c="{"deepBlue"}
  // See: https://mantine.dev/core/text/ for the list of props

  return (
    <Container fluid miw={"25%"}>
    <form
      onSubmit={form.onSubmit((values) => {
        const payload: LoginUserRequestBody | RegisterUserRequestBody = pageMode === "Login"
          ? { email: values.email, password: values.password }
      	  : { ...values };
    	performAuth.mutate(payload);
      })}
      id="login-form"
     >    
        <Title order={1} tt="capitalize" c={"deepBlue"} py="md">
          {pageMode}
        </Title>
        {pageMode === "Register" && (
          <>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="John"
              {...form.getInputProps("name")}
              py="sm"
              type={"text"}
              size="md"
            />
            <TextInput
              withAsterisk
              label="Phone Number"
              placeholder="944143256"
              {...form.getInputProps("phoneNumber")}
              type={"text"}
              py="sm"
              size="md"
            />
            <Textarea
              withAsterisk
              label="Address"
              {...form.getInputProps("address")}
              py="sm"
              size="md"
            />
          </>
        )}
        <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          {...form.getInputProps("email")}
          type="email"
          py="sm"
          size="md"
        />
        <TextInput
          withAsterisk
          label="Password"
          placeholder="**********"
          {...form.getInputProps("password")}
          type="password"
          py="sm"
          size="md"
        />

        <Group align="apart">
          {pageMode === "Login" ? (

            <Text fz="xs" ta="left">
              First time? Click{" "}
              <Anchor
                onClick={() => {
//                  form.reset();
//                  setPageMode("Register");
                }}
              >
                here
              </Anchor>{" "}
              to register
            </Text>


          ) : (
            <Text fz="xs" ta="left">
              Already a registered user? Click{" "}
              <Anchor
                onClick={() => {
                  form.reset();
                  setPageMode("Login");
                }}
              >
                here
              </Anchor>{" "}
              to login
            </Text>
          )}
          <Button type="submit">{pageMode}</Button>
        </Group>
      </form>
    </Container>
  );
};

export default LoginForm;
