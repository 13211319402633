import React  from 'react';
import { Drawer, Title,Text } from '@mantine/core';
import { TerminalNodesAndLabel } from '../../types/interfaces';
import TerminalsTable from '../metadata/TerminalsTable';
import ThumbnailRenderer from '../metadata/ThumbnailRenderer';

const GraphControlsDrawer = ({
    isOpen,
    onClose,
    nodeData,
  }: {
    isOpen: boolean;
    onClose: () => void;
    nodeData: TerminalNodesAndLabel | null; // Allow for null value    
  }) => {

    // //<Title order={1}>Element information</Title>
  const drawerTitle = nodeData ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Title order={4}>{nodeData.label}</Title>
      <Text size="sm" c="dimmed">ID: {nodeData.id}</Text>
    </div>
  ) : (
    <Text>Element information</Text>
  );    

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title={drawerTitle} 
      padding="md"
      position="left"
      size={225}
      // removes the overlay entirely, which allows interaction with the main content      
      withOverlay={false}  
    >
      {/* Safely render TerminalsTable only if terminalNodes is available */}      
      {nodeData && <TerminalsTable terminals={nodeData.terminalNodes} 

      verticalSpacing="sm"
      horizontalSpacing="lg"
      captionSide="bottom"
      /> }

      {/* Safely render ThumbnailRenderer only if terminalNodes is available */}      
      {nodeData && nodeData.terminalNodes && <ThumbnailRenderer nodes={nodeData.terminalNodes}/> }

    </Drawer>
  );
};

export default GraphControlsDrawer;