import { useQuery } from "@tanstack/react-query";
import { useLoginStore } from "../store/useLoginStore";
import { fetchCustomers  } from "../util/http";

export const useCustomersQuery = () => {
  // "object destructuring" is pulling out one of the return values,
  // enclosing it/them in curly braces.
  const { accessToken } = useLoginStore();
  const token = accessToken || "";
  
  return useQuery({
    queryKey: ["customers"],

    queryFn: ({ signal }) =>
      fetchCustomers({
        signal,
        accessToken: token,
      }),

  });
};

