import { useQuery } from "@tanstack/react-query";
import { fetchFlowByFilename } from "../util/http";

export const useTableauPrepFlowQueryWithFilename  = (filename: string) => {
  
    const { data, isLoading, error } = useQuery({
      queryKey: ["flow-filename-tableau-prep", filename],

      queryFn: ({ signal }) =>
        fetchFlowByFilename ({signal, 
          filename }),        
    });
      
    return { data, isLoading, error };
  };
