import React, { useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { ActionIcon, Badge,Box, Image, Text, Tooltip  } from '@mantine/core';
import { Product } from '../../types/interfaces';
import { useProductQuery } from '../../hooks/useProductsQuery';
import { IconRefresh } from '@tabler/icons';


/* Note: see: https://www.mantine-react-table.com/docs/examples/react-query for using react query with
 * pagination, filters, and sorting. It's done on the server side though. Currently on the backend,
 * I don't believe that's enabled in the Spring code, so I'll skip it.
 * 
 * The code, if/when I implement it is: 
 * mantine-react-table/apps/mantine-react-table-docs/examples/react-query/sandbox/src/TS.tsx
 * 
 * My implementation here is based upon that code + the Basic example + enable-detail-panel
 * mantine-react-table/apps/mantine-react-table-docs/examples/enable-detail-panel/sandbox/src/TS.tsx 
 * 
 * Note: since I'm not memo'izing, as the author says is necessary, my state should anything change,
 * will be stale.
 */



/**
 * A Product is:
 * 
 *  id: number;
 *  name: string;
 *  description: string;
 *  price: number;
 *  imageUrl: string;
 *  isAvailable: boolean;
 *  deliveryTime: number;
 *  category: { id: number; name: string };
 */

const ProductTable = () => {
  const {data, isError, isLoading, isFetching, refetch} = useProductQuery();
  const fetchedProducts = data ?? [];
  const totalRows = fetchedProducts.length;

  // should be memoized or stable
  // nested data is ok, see accessorKeys in ColumnDef below
  // ADT setting the size to 50,because otherwise the right most
  // column header bleeds over outside of the container. 
  // I'm sure there's a way to dynamically control this using css
  // &/or some mantine property, but I don't know what that is.
  const columns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: 'imageUrl',
        header: '',
        enableSorting: false,       // disable sorting for this column
        enableColumnFilter: false,  // disable all column filtering for this column (it's singular "Filter")
        enableHiding: false,        // not hideable either        
        Cell: ({ cell }) => <Image src={cell.getValue<string>()} height={50} fit="contain" />, 
        size: 50,
      },     
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'category.name', // Notice dot notation to get the child of category
        header: 'Category',
        size:50,
      },       
      {
        accessorKey: 'price',
        header: 'Price',
        Cell: ({cell}) => <Text>&#8377; {cell.getValue<number>().toFixed(2)} </Text>,
        size:70,
      },
      {
        accessorKey: 'isAvailable',
        header: 'Available',
        Cell: ({ cell }) => 
            cell.getValue<boolean>() ? (
                <Badge color="green" variant="light">
                  In stock
                </Badge>
              ) : (
                <Badge color="red" variant="light">
                  Out of stock
                </Badge>
              ),
        size: 50,
      },      
      {
        accessorKey: 'deliveryTime',
        header: 'Delivery Time',
        size: 50,
      },         
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data : fetchedProducts,
    rowCount: totalRows,
    initialState:{ pagination: { pageSize: 50, pageIndex: 0 } },    
    // The next two mask the weird blue up/down arrows that
    // I guess I introduce by having the font blue? I'm
    // not sure how to just make the blue boxes around
    // up/down arrows to be transparent or white?
    mantineTopToolbarProps: {bg: 'blue', c:'green'},
    mantineTableHeadCellProps: { c:'white', bg: 'blue'},    
    enableToolbarInternalActions: false,  
    enableColumnResizing: true,
    layoutMode: 'grid',    
    mantineToolbarAlertBannerProps: isError ? {
        color: 'red',
        children: 'Error loading data',
    } : undefined,    
    renderTopToolbarCustomActions: () => (
        <Tooltip label="Refresh Data">
          <ActionIcon onClick={() => refetch()}>
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
    ),
    renderDetailPanel: ({ row }) => (
        <Box
          style={{
            display: 'grid',
            margin: 'auto',
            // ADT the effect is it seems to indents 5% on both the left & the right
            width: '90%',
          }}
        >
            <Text>{row.original.description}</Text>
        </Box>
    ),
    state: {
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      },
  });
  return <MantineReactTable table={table} />;
};

export default ProductTable;