import React, { useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';

const HelloWorldModal = () => {
  const [opened, setOpened] = useState(false);

  // Function to handle the modal opening
  const openModal = () => {
    setOpened(true);
  };

  // Function to handle the modal closing
  const closeModal = () => {
    setOpened(false);
  };

  return (
    <>
      <Button onClick={openModal}>Open Modal</Button>
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Hello World"
      >
        <Text>This is a Mantine modal.</Text>
      </Modal>
    </>
  );
};

export default HelloWorldModal;