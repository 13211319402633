import { Button } from "@mantine/core";
import ProductForm from "../forms/AddProductForm";
import { openModal } from "@mantine/modals";

const AddProductButton = () => {
return (
<Button
                type="button"
                variant="outline"
                onClick={() => {
                  openModal({
                    title: "Add new product",
                    children: <ProductForm />,
                    centered: true,
                  });
                }}
              >
                Add a product
              </Button>

);

};

export default AddProductButton;