import {  Select, Tooltip } from "@mantine/core";
import { useNodeNameQuery } from "../../hooks/useNodeQuery";
import { useNotificationStore } from "../../store/useNotificationStore";
import LoadingSpinner from "../display/LoadingSpinner";


const NodeNamePicker = ({
    filename,
    nodeName,
    setNodeName,
   
} : {
    filename:string;
    nodeName: string;
    setNodeName: (nodeName: string) => void;
     
}) => {
    const notificationStore = useNotificationStore();

    const {data, isLoading, error } = useNodeNameQuery(filename);

    if (isLoading) {
        return <LoadingSpinner/>
     }
 
     if (!data) {
         notificationStore.errorNotification("Unable to load node names");
         return;
     }
     const selectData = data!.map((nodeName) => {
        return { value: nodeName, label: nodeName };
     });
 
     return (
        <Tooltip label="The exact name of the nodes to search" position="bottom" withArrow={true}>
                <Select
                    label={"Name"}
                    data={selectData}
                    maxDropdownHeight={280}
                    clearable
                    placeholder="Select node name"
                    nothingFoundMessage="Nothing found"
                    searchable
                    value={nodeName}
                    onChange={(value) => setNodeName(value ?? '')} // Fall back to an empty string if value is null            
                    style={{ flex:1 }}
                />
        </Tooltip>
    );

}
export default NodeNamePicker;
