/**
 * Same as HeaderMapping, but for a footer
 */
import { useLocation } from 'react-router-dom';
import CytoscapeControlPanel from '../components/graph/CytoscapeControlPanel';
import { zoomInHandler } from '../components/graph/cytoscapeHandlers';
import { HeaderConfig } from '../types/interfaces';

import { ReactElement } from 'react';
import CytoscapeControls from '../components/graph/CytoscapeControls';
import React from 'react';
import FooterSimple from './FooterSimple';
import FloatingWrapper from './FloatingWrapper';

// This regular expression will capture the segment after "/app/"
// For example, it matches "/app/file/", "/app/settings/", etc.
const routePattern = /^\/app\/([^\/]+)\/.*/;


const footerConfig = (pathname: string) => {
    console.log("footerConfig, will attempt to match " + pathname);
    const routePattern = /^\/app\/([^\/]+)\/.*/;
    const match = pathname.match(routePattern);
    console.log("regexp match " + match);
    if (match && match[1]) {
        const segment = match[1];
        console.log("match[1] = X" + segment +"X");
        switch (segment) {
            
          case 'file':
          case 'tableau-prep':
            console.log("The string matches file");

  

            const panel =<CytoscapeControlPanel/>;
            if (React.isValidElement(panel)) {
                // There's no reason to think it's null, but let's see. Perhaps
                // more for instructional purposes for the future me.
                // Directly check if the component is not null here, if needed
                return (
                    <FloatingWrapper>{panel}</FloatingWrapper>
                );
            } else {
              // Component is null or not valid, handle accordingly
              return (<FooterSimple>Error loading the CytoscapeControls component.</FooterSimple>);
            }               
          default: 
            console.log("This header area for sale");
            return null;                
        }
    }
  // Define other paths and their respective headers if needed
  // '/some-other-page': <SomeOtherHeaderComponent />,
  return null;
};

export default footerConfig;