import React, { useState } from 'react';
import { Button, Modal, Text } from '@mantine/core';
import Draggable from 'react-draggable';

const DraggableModal2 = () => {
  const [opened, setOpened] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setOpened(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setOpened(false);
  };

  return (
    <>
      <Button onClick={openModal}>Open Draggable Modal</Button>
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Draggable Modal"
        withCloseButton={false}
        centered={false} // Disable auto centering to allow dragging
        withinPortal={false} // Render Modal outside of Portal to enable dragging
      >
        <Draggable>
          <div>
            <Text>This is a draggable Mantine modal.</Text>
            <Button onClick={closeModal}>Close</Button>
          </div>
        </Draggable>
      </Modal>
    </>
  );
};

export default DraggableModal2;