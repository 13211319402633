import React, { useState } from "react";
import { Link, Outlet, useActionData, useLocation, useNavigate } from "react-router-dom";

import { 
   AppShell, Burger, Button, Grid, ScrollArea, Title, useMantineTheme
 } from "@mantine/core";

import "@mantine/core/styles.css";
import { useDisclosure } from "@mantine/hooks";
import ListCategories from "../components/products/ListCategories";
import { useLoginStore } from "../store/useLoginStore";
import ProductSearchBox from "../components/header/ProductSearchBox";
import { IconDots, IconLogout } from "@tabler/icons";
import axios from "axios";
import { useActiveCategoryStore } from "../store/useActiveCategoryStore";

import { HeaderSearch } from "./HeaderSearch";
import { NavbarSimple } from "./NavbarSimple";
import SidebarContent from "./SidebarContent";
import DataUpdater from "../components/DataUpdater";
import NodeDrawer from "../components/drawers/NodeDrawer";
import headerConfig from "./HeaderMapping";
import footerConfig from "./FooterMapping";
import { useShouldShowFooter } from "../hooks/useShouldShowFooter";


// For better or worse, this is embedded as an Outlet within Layout.
// Makes it kind of confusing, and ultimately consider incorporating 
// it into Layout.tsx. Although maybe since it's for only logged in users, 
// it serves a purpose. Think about it.

// Per: https://stackoverflow.com/questions/75785717/i-am-using-createbrowserrouter-what-is-the-proper-way-to-have-header-and-footer
// and: https://reactrouter.com/en/main/upgrading/v6-data#differences

// Inter is the fontFamily that comes with it.
// For Mantine v 7, withGlobalStyles and withNormalizeCSS
// appear gone.
// I added defaultColorScheme. Setting it to "dark" and the
// left sidebar is still white. Interesting.

const CoreLayout = () => {
  const [opened, { toggle }] = useDisclosure();
  const {logoutUser, isLogged, accessToken  } = useLoginStore();
  const categoriesStore = useActiveCategoryStore();
  const navigate = useNavigate();
  const shouldShowFooter = useShouldShowFooter();
  const theme = useMantineTheme();  

  // When using createHashRouter, location.pathname doesn't change, and thus 
  // doesn't work. Use the userLocation hook's pathname property which
  // reflects everything after the # in the URL
  const {pathname} = useLocation();
  console.log("location " + pathname);  

  // For the right sidebar
  const [isSidebarOpened, setSidebarOpened] = useState(false);  
  const [data, setData] = useState<Record<string,any>>({
      name: "John Doe",
      age: 32,
      location: "New York",
      occupation: "Engineer"
  });

  const handleDataChange = (newValue: Record<string,any>) => {
    setData(newValue);
  };
  /**
   * For the NodeDrawer component. Replace with useDisclosure
   */
  
  const [isOpen, setIsOpen] = useState(false);

  const closeDrawer = () => setIsOpen(false);
  const openDrawer = () => setIsOpen(true);



  // Set the authorization token here. 
  // It is referenced basically globally. Axios
  // intercepts all calls and injects the header. Without,
  // 401 errors will fly all over the place when using Axios
  // This is temporary until:
  // 1. We switch to fetch
  // 2. And figure out how to do the same thing in fetch/wretch/etc.
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  // This seems major kludgy. If it's not on the "/app" page, don't allocate
  // screen space for the navbar (the sidebar). But this seems like a wacky
  // way to accomplish it. Figure out a way to use Outlet or something.
  const navbarProps = pathname === '/app' ? 
    {
      navbar : {
      width: 300, 
      breakpoint: 'sm',
      collapsed: { mobile: !opened }
      }
    } : {};

    //const asideStyle = { width: '250px' }; // define your styles here
    const asideStyle = { width: '15%' }; // define your styles here    

  // Get the header to use, based upon the path
  const currentHeader = headerConfig(pathname); // ] || null;
  console.log("current header " + currentHeader);  

  // And the footer
  const currentFooter = footerConfig(pathname);

  // Conditionally construct footer properties
  const footerProps = shouldShowFooter 
    ? { footer: { height: { base: 100, md: 100 } } } 
    : {};


  return (
    <AppShell
      header={{ height: { base: 60, md: 70 } }}
      navbar={{
        width: { base: 200, md: 225 },
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      // commenting out footer for now
      // base refers to the default value that is applied. It sets the height
      // of the footer to 100px when the viewport size is at the base or default level, 
      // which would typically be for mobile or smaller viewports.

      // md refers to a breakpoint defined by Mantine's theme or your custom settings.
      // When the viewport width reaches the md (medium) breakpoint, the height of the
      // footer will change to 180px. This allows the footer to have a different height
      // on larger screens compared to smaller ones.

      // I could just set base to 100, and be done with it, but in case I want to 
      // alter the small screen size vs. a larger one, then keep them both here.
      {...footerProps}  // Spread the footerProps      
      p={{ base: 'md' }}
      
      styles={(theme) => ({
        main: {
          

        },          
        navbar: {
          // Set the height to 100% so that it goes over the footer. Otherwise
          // the footer stretches with width of the page and looks funny.
          height: '100%',
        },
        footer: {
          // must be set so that in FloatingWrapper, the height can be calculated
          // dynamically using 'var(--app-shell-footer-height)'          
          height: '100px',
        }
      })
    }


      

    > 
    <AppShell.Header>{currentHeader}</AppShell.Header>
{/*
      <AppShell.Header>
        <Grid>
      <Grid.Col span={12}>
        <Burger opened={opened} onClick={openDrawer} aria-label="Toggle navigation" />
        </Grid.Col>
        </Grid>        
        <HeaderSearch/>
      </AppShell.Header>
    */}

  


  

      <AppShell.Navbar p="md">
          <NavbarSimple/>

          {/* This is the left category column. Will stomp on the navbar*
          <AppShell.Section >
                    <Title pb="xs" order={2} c={"blue"}>
                      Categories
                    </Title>                  
                  </AppShell.Section>
                  <AppShell.Section grow my="md" component={ScrollArea}>
                    <ListCategories/>
                  </AppShell.Section>
  */}
      </AppShell.Navbar>
      {/* Adding AppShell.Section + AppShell.Aside for sidebar */}

      <AppShell.Section>
        <AppShell.Main>
{/* Experimentations in sidebars. Uncomment to see
        <DataUpdater onUpdateData={setData} />  
*/}

{/*
more experiments
          <Button onClick={() => setSidebarOpened(!isSidebarOpened)}>
                Toggle sidebar
          </Button>       
*/}

      <Outlet context= {{data, onDataChange: handleDataChange}} />

        </AppShell.Main>
      <NodeDrawer  isOpen={isOpen} closeDrawer={closeDrawer}/>

        {isSidebarOpened && (       
      <AppShell.Aside style={asideStyle}>
      

        <SidebarContent data={data} />
      </AppShell.Aside>              
        )}
      </AppShell.Section>      

    {/* commenting out for the moment. Relying on
        cytoscapes height being less than the windows size.
        see CytoscapeGraph.tsx's  cyContainerStyle

        Note: that that cyContainerStyle might not even be necessary ?
        */}
        {/*<AppShell.Footer>{currentFooter}</AppShell.Footer> */}
  </AppShell>

  );
};

export default CoreLayout;

