import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppShell, Container, Box, Title } from '@mantine/core';
import { useTableauPrepFlowQueryWithFilename } from '../hooks/useTableauPrepFlowQuery'; 
import { useNotificationStore } from '../store/useNotificationStore';

const FlowPage = () => {
  const { filename } = useParams<{ filename?: string }>();
  const { errorNotification } = useNotificationStore();

  if (!filename) {
    errorNotification('No filename provided', 'Error');
    return null; // Render nothing since we're showing a notification
  }

  const containerRef = useRef<HTMLDivElement>(null);

  const flowQuery = useTableauPrepFlowQueryWithFilename(filename);
  const { data: flowDocument, isLoading, error } = flowQuery;

  useEffect(() => {
    if (isLoading) {
      console.log('Loading flow document...');
      return;
    }

    if (error) {
      console.error('Error fetching flow document:', error);
      errorNotification('Failed to fetch the flow document', 'Error');
      return;
    }

    if (containerRef.current && flowDocument) {
      containerRef.current.textContent = JSON.stringify(flowDocument, null, 2); // Direct output to textContent
    }
  }, [flowDocument, isLoading, error, errorNotification]);

  return (
    <AppShell>
      <Container fluid style={{ padding: 0 }}>
        <Title order={2} style={{ textAlign: 'center', margin: '20px 0' }}>
          {filename} flow document
        </Title>
        <Box ref={containerRef} style={{
          whiteSpace: 'pre-wrap', // Ensure the JSON respects white space and new lines
          width: '100%',
          height: '80vh',
          overflow: 'auto',
          backgroundColor: '#f5f5f5',
          padding: '15px',
          borderRadius: '4px',
          fontFamily: 'Courier New, Courier, monospace'
        }} />
      </Container>
    </AppShell>
  );
};

export default FlowPage;
