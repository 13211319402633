import { Divider, Stack } from '@mantine/core';
import { IconZoomIn, IconZoomOut, IconChevronLeft, IconChevronRight, IconChevronDown, IconCrosshair, IconChevronUp } from '@tabler/icons';

import { useRefStore } from '../../provider/AppProvider';
import { CYTOSCAPE_INSTANCE_KEY } from '../../types/constants';
import { panLeftHandler, panUpHandler, panDownHandler, panRightHandler, zoomInHandler, zoomOutHandler, fitGraphHandler } from './cytoscapeHandlers';
import { Core } from 'cytoscape';
import ControlButton from '../buttons/ControlButton';

const CytoscapeControlPanel = () => {

  const { refStore } = useRefStore<Core>(); // Specify the generic parameter ie. <Core>

  // Now access the specific cyRef using the key you've set
  const cyRef = refStore[CYTOSCAPE_INSTANCE_KEY];   

  
  const gap = '3px'; // Smaller gap size for compact spacing
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap }}>
      <div style={{ display: 'flex', flexWrap: 'nowrap'}}>
        <ControlButton
          icon={<IconZoomOut size={16} />}
          label="Zoom Out"
          onClick={() => cyRef && zoomOutHandler(cyRef)} // Placeholder for actual event handler
        />
      </div>
      <Divider orientation="vertical" size="sm"   style={{ margin: '0 20px', alignSelf: 'center' }} />      

      <ControlButton
        icon={<IconChevronLeft size={16} />}
        label="Left"
        onClick={() => cyRef && panLeftHandler(cyRef)} // Placeholder for actual event handler
      />

      <Stack gap={gap} align="center">
        <ControlButton
          icon={<IconChevronUp size={16} />}
          label="Up"
          onClick={() => cyRef && panUpHandler(cyRef)} // Placeholder for actual event handler
        />
        <ControlButton
          icon={<IconCrosshair size={16} />}
          label="Recenter"
          onClick={() => cyRef && fitGraphHandler(cyRef)} // Placeholder for actual event handler
        />
        <ControlButton
          icon={<IconChevronDown size={16} />}
          label="Down"
          onClick={() => cyRef && panDownHandler(cyRef)} // Placeholder for actual event handler
        />
      </Stack>

      <ControlButton
        icon={<IconChevronRight size={16} />}
        label="Right"
        onClick={() => cyRef && panRightHandler(cyRef)} // Placeholder for actual event handler
      />

      {/* Zoom Control Buttons */}
      <Divider orientation="vertical" size="sm"   style={{ margin: '0 20px',  alignSelf: 'center' }} />      
      <div style={{ display: 'flex', flexWrap: 'nowrap'}}>
      {/* Moved to left of arrow        
        <ControlButton
          icon={<IconZoomIn size={16} />}
          label="Zoom In"
          onClick={() => cyRef && zoomInHandler(cyRef)} // Placeholder for actual event handler
        />
    */}
        <ControlButton
          icon={<IconZoomIn size={16} />}
          label="Zoom In"
          onClick={() => cyRef && zoomInHandler(cyRef)} // Placeholder for actual event handler
        />
      </div>
    </div>
  );
};

export default CytoscapeControlPanel;